import {useState, useEffect} from 'react';

// import PropTypes from 'prop-types';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // Divider,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {  KeyboardDatePicker } from '@material-ui/pickers';
import {
  DeviceLocation,
  DeviceProps,
} from '../common/entityUtilities';


import { VentilationMode, Apparatus } from '../common/entityUtilities';




type UIPropsForNewDeviceDialog = {
 deviceId: string;
 description: string;
 org: string;
 createdAt: Date;
 patientIdentifier: string;
 location: string;
};

const emptyDeviceUIState:UIPropsForNewDeviceDialog = {
    deviceId: '',
    description: '',
    patientIdentifier: '',
    org: '',
    createdAt: new Date(),
    location: ''
};

export type DeviceHistorySnapshotDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  deviceInformation:DeviceProps|null,
  allOrgs: any[]

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        width: 200,
      },
    },
  }),
);

export const DeviceHistorySnapshotDialog = (props:DeviceHistorySnapshotDialogProps):JSX.Element=> {
  const classes = useStyles();
  const {isOpen, dialogComplete, deviceInformation, allOrgs}  = props;
  

  const [dialogValue, setDialogValue] = useState(emptyDeviceUIState);
  const [org, setOrg] = useState('');
  const [ventilationMode, setVentilationMode] = useState("");

  const [errors, setErrors] = useState<any>({});

  const allModes = [VentilationMode.HFJV, VentilationMode.HFOV, VentilationMode.Bubble];
  const allApparatus = [Apparatus.Canula, Apparatus.Mask, Apparatus.Prongs];

  useEffect(()=>{

    const fillUp = async ()=>{
      if(deviceInformation===null){
        console.log('New device initializing');
        setDialogValue(emptyDeviceUIState);
        return;      
      }


      console.log('deviceInformation in [useEffect]:', deviceInformation);
      let dialogValues:UIPropsForNewDeviceDialog =  {...emptyDeviceUIState, 
        patientIdentifier: deviceInformation.patientIdentifier,
        createdAt: deviceInformation?.createdAt,
        description: deviceInformation?.description,
        deviceId: deviceInformation.deviceId,
        location: deviceInformation.deviceLocation
        
      };
      console.log('Setting dialogValues:', dialogValues);
      setDialogValue(dialogValues);
      if (deviceInformation?.orgId){
        setOrg(deviceInformation?.orgId);
      }


      console.log('Org:', deviceInformation?.orgId);
      const orgName = allOrgs.find(item=>item._id==dialogValues.org)?.name;
      console.log('Org name:', orgName);

 

    }

    console.log('devicettoupdate:', deviceInformation);
    fillUp();
    
   
  },[deviceInformation]);
  


  


  const handleClose = () => {
    // new way of reseting dialog state when closing.
    setErrors({});
    setDialogValue(emptyDeviceUIState);
    dialogComplete();
  };


  


  //Ugh, this UI should to be split/updated for two components, one for user and the
  // other for parents
  return (

    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <form>

        <DialogContent className={classes.root}>
          <div>
            <Typography
              variant="h5"
            >
                Device History
            </Typography>

            <TextField
              autoFocus
              aria-readonly
              label="Time stamp"
              value={dialogValue?.createdAt && 
                new Date(dialogValue?.createdAt).toLocaleDateString('en-us') + ", " + 
                new Date(dialogValue?.createdAt).toLocaleTimeString('en-us')}/>

            <TextField
              autoFocus
              aria-readonly
              label="Device"
              margin="dense"
              style={{ width: '80%' }}
              type="text"
              value={dialogValue.deviceId}
            />

            <TextField
              autoFocus
              aria-readonly
              label="Description"
              margin="dense"
              style={{ width: '80%' }}
              type="text"
              value={dialogValue.description}
            />
            <TextField
              autoFocus
              aria-readonly
              error = {errors.email}
              fullWidth
              helperText = {errors.email}
              id="patientidentifier"
              // inputProps={{
              //   readOnly: !isNewSubject
              // }}
              label="Patient Identifier"
              margin="dense"
              style={{ width: '80%' }}
              type="text"
              value={dialogValue.patientIdentifier}
            />

            <TextField
              autoFocus
              aria-readonly
              label="Device Location"
              margin="dense"
              style={{ width: '80%' }}
              type="text"
              value={dialogValue.location}
            />
        
          
            <FormControl fullWidth>
            <InputLabel shrink={true} 
                 >Org</InputLabel>
            <Select 
                id="org"
                label="Organization"
                disabled={true}
                value = {allOrgs.find(item=>item._id==org)?._id}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, org: event.target.value});
                  setOrg(event.target.value);
                  console.log('Current org:', org);
                  console.log('Setting Org:', event.target.value);
                  }
                }
              >
                 {allOrgs && allOrgs.map((option) => (
                  <MenuItem
                    key={option._id}
                    value={option._id}
                  >
                    {option.name} @ {option.location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


          
  

          </div>         

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant='outlined'
            onClick={handleClose}
          >
              Close
          </Button>
        
        </DialogActions>
      </form>

    </Dialog>
  );
};
