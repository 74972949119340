/* eslint-disable react/no-multi-comp */
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import * as RouteNames from '../../common/routeNames';
import { setUserInfo } from '../../common/apiSetUtilities';
// import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  IconButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import validator from 'validator';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import { useUserState, useUserDispatch, UserActionTypes} from '../../context/UserContext';
import { SharedSnackbarConsumer } from '../../context/SnackbarContext';
import useStyles from './styles';
import { convertUserDataToUserPropType } from '../../common/entityUtilities';
import { checkIfEmailIsTaken } from '../../common/apiGetUtilities';
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;


function UserSettings(){
  const classes = useStyles();
  const user = useUserState();

  // Global
  const userState = useUserState();
  const userDispatch = useUserDispatch();
 
  // local
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [registeredDate, setRegisteredDate] = useState(userState.createdAt);
  const [email, setEmail] = useState(userState.email);
  const [userEmail, setUserEmail] = useState(userState.email);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [toDashboard, setToDashboard] = useState(false);

  const [firstName, setFirstName] = useState(userState.firstname);
  const [lastName, setLastName] = useState(userState.lastname);
  const [signature, setSignature] = useState(userState.signature);
  const [authProvider, setAuthProvider] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');


  useEffect(() => {

    const setFunction = () => {

        // is it better to load data here then cache it for future usage?
        // instead of having all the info a larger entitiy.
        let localEmailCopy = userState.localEmail;

        //console.log('[UserSettings] The user state', JSON.stringify(userState));
        console.log('[UserSettings] userState email:', userState.email);
        const providerData = userState.firebaseUser.firebaseUserObject?.providerData[0];
        console.log('[UserSettings] Auth Provider ID:', providerData?.providerId);
        // can be 'password'
        // 'google.com'
        // or 'phone' 
        //
        setAuthProvider(providerData?.providerId);

        if (providerData?.providerId == 'phone'){
        setPhoneNumber(providerData?.phoneNumber); // this should be saved in the emmauser db object
        console.log('Provider says phone number is:', providerData?.phoneNumber);
        
        }

        setDisplayName(userState.localDisplayName);
        if (userState.firebaseUser.email !== null){
        console.log('Setting userEmail from firebaseUser email:', userState.firebaseUser.email);
        setEmail(userState.firebaseUser.email);
        setUserEmail(userState.firebaseUser.email);
        localEmailCopy = userState.firebaseUser.email;
        }
        else{
        console.log('Firebase user did not have email');
        console.log('user email:', userEmail);
        console.log('userState email:', userState.email);
        if (userEmail != null && userEmail != undefined && userEmail != '') {
            console.log('Setting userEmail to:',userEmail);
            setEmail(userEmail);
            //the state variable email is never used anywhere!
            
            setUserEmail(userEmail);
            localEmailCopy = userEmail;
            setIsEmailValid(true);
        }
        else if (userState.localEmail != null && userState.localEmail != undefined && userState.localEmail != ''){
            console.log('Setting userEmail from userState localEmail:', userState.localEmail);
            setEmail(userState.localEmail);
            setUserEmail(userState.localEmail);
            localEmailCopy = userState.localEmail;
            setIsEmailValid(true);
        }
        }


        console.log('[UserSettings] userEmail:', userEmail);
        console.log('[UserSettings] localEmailCopy:', localEmailCopy);
        if(userState.created){
        setRegisteredDate(new Date(userState.created).toDateString());
        }
        setPhotoURL(userState.firebaseUser.photoURL);
    };

    if (userState.firebaseUser) {
        setFunction();
    }
   
    


  }, []);

  const modifyUserSettings = () => {
    //Firebase user should always exist since that is our main log in.
    const firebaseUser = userState.firebaseUser;

    // Also note that an empty use is created if no matching user was found
    // in the mongodb. It is of type UserProps and is used by the user context

    // the call to register the user needs to be put elsewhere
    // but for now this will do since no other function calls the setuser
    const addingNewUserToDb = (userState.authLevel < 0); // -1 for unregisterd
    // Check to see if it is an unregistered user
    // then register or update accordingly.
    const mongoUserValuesToUpdate= {};//:Partial<EmmaUserSchemaProps> 
    if(addingNewUserToDb){
      mongoUserValuesToUpdate.authLevel = 0;
      mongoUserValuesToUpdate.created = new Date();//new Date().toISOString();
      mongoUserValuesToUpdate.uid = firebaseUser.uid;
      mongoUserValuesToUpdate.email = firebaseUser.email;
      mongoUserValuesToUpdate.phoneNumber = phoneNumber;
      console.log('firebaseuser email:', firebaseUser.email);
    }
    mongoUserValuesToUpdate.firstname = firstName;
    mongoUserValuesToUpdate.lastname = lastName;

    if (firebaseUser.email === null){
      let lowercaseEmail = String(userEmail).toLowerCase();
      mongoUserValuesToUpdate.email = lowercaseEmail;
      console.log('useremail to update with:', lowercaseEmail);
    }
    console.log('uservalues to update for new user:', mongoUserValuesToUpdate);
    setUserInfo(firebaseUser, userState._id, mongoUserValuesToUpdate)
      .then(updatedUser=>{
        // The updated user will have the emmauserid/mongoid
        console.log('The updated cpapuser:', updatedUser);
        const fixedUser = convertUserDataToUserPropType(updatedUser);
        console.log('The user', fixedUser);
        const createdDateString = new Date(updatedUser.createdAt).toDateString();
        console.log('Created at date string:', createdDateString);
        setRegisteredDate(createdDateString);
        // don't think this update the user in the list
        userDispatch({ type: UserActionTypes.UPDATE, ...fixedUser });
      });
  };

  const buttonText = userState.authLevel < 0 ? 'Register': 'Update';

  if (toDashboard) {
    console.log('Rerouting to dashboard page');
    return (<Redirect to={RouteNames.DASHBOARD}/>);
  };
  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          display="inline"
          gutterBottom
          variant="h3"
        >
          User Information 
        </Typography>
        <Divider my={2}/>
        
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
                
            <Card mb={6}>
            <CardContent>
                <Typography
                gutterBottom
                variant="h4"
                >
                {userState.authLevel < 0 &&
                    <div>

                    <Typography gutterBottom variant='body1' color='secondary'> We need some information first before registering you.
                    </Typography>
                    </div>
                }
                </Typography>
                <Grid
                container
                spacing={3}
                xs={12}
                sm={12}
                md={12}
                >

                <Grid
                    item
                    md={4}
                    xs={8}
                    sm={8}
                >
                    <TextField
                    id="useremail"
                    label="Email"
                    fullWidth
                    disabled={authProvider !== 'phone'}
                    m={2}
                    onChange={(e) => {
                        if (validator.isEmail(e.target.value)){ //is the entered text in the right format for an email?

                        setUserEmail(e.target.value);
                        setEmailValidationMessage('Entered email is ok');
                        checkIfEmailIsTaken(userState?.firebaseUser, e.target.value).then(
                            //is there any other cpapuser in the database with this email?
                            (status)=>{
                            console.log('checkifemailistaken returned:', status);
                            if (!status){
                                setIsEmailValid(true);
                            }
                            else{
                                setEmailValidationMessage('This email is already taken. Type in a different email address.');
                                setIsEmailValid(false);
                            }
                            }
                        )
                        }
                        else {
                        setEmailValidationMessage('Enter a valid email');
                        setIsEmailValid(false);
                        }
                    }}
                    value={userEmail}
                    variant="outlined"
                    />
                </Grid>

                <Grid
                    item
                    sm={4}
                    md={4}
                    xs={4}
                    >
                    <Typography variant='body2' color='primary'>
                        {emailValidationMessage}
                    </Typography>
                </Grid>



                <Grid
                    item
                    md={8}
                    xs={8}
                    sm={8}
                >
                    <TextField
                    id="firstname"
                    label="First Name"
                    m={2}
                    onChange={(e)=>setFirstName(e.target.value)}
                    value={firstName}
                    variant="outlined"
                    />
                </Grid>
                <Grid
                    item
                    md={8}
                    xs={8}
                    sm={8}
                >
                    <TextField
                    id="lastname"
                    label="Last Name"
                    m={2}
                    onChange={(e)=>{
                        console.log('lastname:', e.target.value);
                        setLastName(e.target.value);}}
                    value={lastName}
                    variant="outlined"
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={4} sm = {4}
                >
                    <CenteredContent>
                    {photoURL !== ''?
                        (
                        <Avatar
                            alt=""
                            src={photoURL}
                        />):
                        (
                        <Avatar
                            alt=""
                            src="/static/img/avatars/avatar-1.jpg"
                        />)}
                    </CenteredContent>
                </Grid>
            


                {userState.authLevel >=0 ?  (
                    <Grid
                    item
                    md={4}
                    xs={8}
                    sm={6}
                    >
                    <TextField
                        InputProps={{
                        readOnly: true
                        }}
                        id="registeredDate"
                        label="Registration Date"
                        m={2}
                        value={registeredDate}
                        variant="outlined"
                    />
                    </Grid>): (<Grid item></Grid>)}
                </Grid>
                <Grid
                container
                spacing={2}
                xs={12}
                >


                <Grid item xs={8}>


                    <SharedSnackbarConsumer>
                    {({openSnackbar}) => (
                        <Button
                        color="primary"
                        disabled={!isEmailValid}
                        onClick= {()=> {
                            openSnackbar('Saving parent settings');
                            modifyUserSettings();}}
                        variant="contained"
                        >
                        {buttonText}
                        </Button>

                    )
                    }
                    </SharedSnackbarConsumer>


                </Grid>


                </Grid>

            </CardContent>
            </Card>
                

          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}



export default UserSettings;

