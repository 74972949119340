import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { 
  Typography, 
  Link,
  Grid,
  List,
  ListItemText,
  ListItem as MuiListItem
} from '@material-ui/core';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2px
    4px;
  background: white;
  position: relative;
`;
// padding: ${props => props.theme.spacing(1) / 4}px
// ${props => props.theme.spacing(4)}px;
// background: ${props => props.theme.palette.common.white};
const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: 2px;
  padding-right: 2px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;
// padding-left: ${props => props.theme.spacing(2)}px;
// padding-right: ${props => props.theme.spacing(2)}px;
const Footer = () =>{
  return (
   
    <Wrapper style={{width:'100%'}}>

        <Grid container item  spacing={1} alignItems="center">
          <Grid item xs={1} sm={2} md={4}>

          </Grid>
          <Grid item xs={5} sm={4}>
            <Typography>© 2023 - Project Kiwi</Typography>
          </Grid>
        </Grid>
    

    
    </Wrapper>
  );
};

export default Footer;
