import React, {useEffect, useState} from 'react';
import { Button, ThemeProvider} from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useUserState } from '../../context/UserContext';
import { useWiggleEventListState } from '../../context/WiggleEventListContext';
import { emptyWiggleEventState } from '../../common/entityUtilities';

import { muiDataTableTheme } from '../../themes/default';
import { prepareWiggleEventForTable } from '../../common/utilities';

export function WiggleEventInformationTable(props) {

  const initialColumns = [
    {
      name:'patientIdentifier',
      label:'Patient Id',
      options: { }
    },
    {
      name:'position',
      label:'Patient Position',
      options: { }
    },  
    {
      name:'chest_wiggle',
      label:'Wiggle',
      options: { }
    },  
    {
      name:'updatedAt',
      label:'Timestamp',
      options:{

        customBodyRender: (value, tableMeta, updateValue) => (

          new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString('en-US')
        )
      }
    },
    {
      name:'authLevel',
      label:'Status',
      options:{
        display:false,
        customFilterListOptions: { render: v => `Status: ${v}` },
      }

    },
  ];
  const {editOrCreateWiggleEvent, initialAuthLevelFilter, selectedDevice} = props;
  const activeUser = useUserState();
  const { allWiggleEvents, deviceId, setDeviceId } = useWiggleEventListState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Row click', rowMeta);
    editOrCreateWiggleEvent(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          color="primary"
          onClick={()=> {
            const eventInfo = emptyWiggleEventState;
            editOrCreateWiggleEvent({...eventInfo, deviceId: selectedDevice});
          }}
          variant="outlined"
        >
          Log Event
        </Button>
        // <CustomToolbar />
      );
    }
  };

  useEffect ( ()=> {
    if (setDeviceId && selectedDevice){
        console.log('Setting selected device:', selectedDevice);
        setDeviceId(selectedDevice);
    }
  }, [selectedDevice]);

  useEffect(() =>{

    const localFn = async()=>{

      console.log('Setting up all Wiggle events:', allWiggleEvents);
      console.log('Selected device:', selectedDevice);
      const dataForTable = await prepareWiggleEventForTable(activeUser, allWiggleEvents);
      let toDisplay = dataForTable; 
      console.log('ToDisplay:', toDisplay);
      const currentAuthLevel = activeUser.authLevel;
      if(currentAuthLevel <=0){
        // hopefully this is never undefined
        toDisplay = null;
      }
      console.log('Setting table to display:', toDisplay);
      setTableData(toDisplay);
    }

    localFn();
    
  }, [allWiggleEvents]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'patientIdentifier',
            label:'Patient Id',
            options: { }
          },
          {
            name:'position',
            label:'Patient Position',
            options: { }
          },    
          {
            name:'chest_wiggle',
            label:'Wiggle',
            options: { }
          },  
          {
            name:'updatedAt',
            label:'Timestamp',
            options:{}
          },  
          {
            name:'authLevel',
            label:'Status',
            options:{
              display:false,
              filterList:[initialAuthLevelFilter],
              customFilterListOptions: { render: v => `Status: ${v}` },
            }
          },
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
    <MUIDataTable
      columns={columns}
      data={tableData}
      options={options}
      title={'Wiggle Events'}
    />
    </ThemeProvider>
  );
}