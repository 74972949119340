import axios from 'axios';
import { getCpapApiEndpoint, setAxiosDefaultHeaders } from './apiBaseUtilities';

import { 
    UserProps, 
    FirebaseUserProps
  } from './entityUtilities';
import { CpapUserSchemaProps, emptyUserMongoSchema, SubjectSchemaProps, DeviceSchemaProps,
  emptyDeviceMongoSchema,
  emptySubjectMongoSchema, CpapEventSchemaProps, emptyCpapEventMongoSchema, 
  WiggleEventSchemaProps, emptyWiggleEventMongoSchema } from './mongoSchemas';

const cpapApiEndpoint = getCpapApiEndpoint();

export async function sendDeviceCommand(currentFirebaseUser: FirebaseUserProps|undefined,
  deviceId: string, command: string): Promise<any> {

    if(!cpapApiEndpoint || !currentFirebaseUser) return {};

    const {uid, bearerToken } = currentFirebaseUser;
    console.log('currentuser:', currentFirebaseUser);
    setAxiosDefaultHeaders(uid, bearerToken);

    let commandJson = {
      'deviceId': deviceId,
      'command': command
    }

    try{

      const url = `${cpapApiEndpoint}events/command`;
      console.log('POST command:',commandJson);
      const result = await axios.post(url, commandJson);
      if(result.data){
        console.log('POST command result:', result.data);
        return result.data;
      }   
    }
    catch(err){
      return {'error': err} ;
    }

}

export async function setUserInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
    userIdToUpdate:string|undefined,
    propUpdates:Partial<CpapUserSchemaProps>):Promise<Partial<CpapUserSchemaProps>>{
    if(!cpapApiEndpoint || !currentFirebaseUser) return {};
    console.log('Saving id', userIdToUpdate);
    console.log('data?', propUpdates);
    // For some reasoning trying to save the payload doesn't
    // work, gives a 422 or 415 error
    // Maybe fix the need for string properties another day (dlp)
  
    let mongoUser:Partial<CpapUserSchemaProps> = {...propUpdates};
    if(userIdToUpdate === undefined){
      mongoUser = {...emptyUserMongoSchema, ...propUpdates};
    }
  
    const {uid, bearerToken } = currentFirebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    // Doing a post of an entity with a mongodb id will do the update
    // if not a new one is created.
  
    try{
      if(userIdToUpdate === undefined){
        const url = `${cpapApiEndpoint}users`;
        // make sure the mongoUser has all the required properties or
        // the api will return a 422 error.
        console.log('POST to create new cpapuser:', mongoUser);
        const result = await axios.post(url, mongoUser);
        if(result.data){
          return result.data;
        }    
      }else{
        const url = `${cpapApiEndpoint}users/${userIdToUpdate}`;//userToUpdate.userId
        console.log('PUT to update cpapuser:',mongoUser);
        const result = await axios.put(url, mongoUser);
        if(result.data){
          return result.data;
        }   
      }
  
      return mongoUser;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return mongoUser;  
  }


  export async function setSubjectInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
    subjectIdToUpdate:string|undefined,
    propUpdates:Partial<SubjectSchemaProps>):Promise<Partial<SubjectSchemaProps>>{
    if(!cpapApiEndpoint || !currentFirebaseUser) return {};
    console.log('Saving id', subjectIdToUpdate);
    console.log('data?', propUpdates);
    // For some reasoning trying to save the payload doesn't
    // work, gives a 422 or 415 error
    // Maybe fix the need for string properties another day (dlp)
  
    let mongoSubject:Partial<SubjectSchemaProps> = {...propUpdates};
    if(subjectIdToUpdate === undefined){
      mongoSubject = {...emptySubjectMongoSchema, ...propUpdates};
    }
  
    const {uid, bearerToken } = currentFirebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    // Doing a post of an entity with a mongodb id will do the update
    // if not a new one is created.
  
    try{
      if(subjectIdToUpdate === undefined){
        const url = `${cpapApiEndpoint}subjects`;
        // make sure the mongoUser has all the required properties or
        // the api will return a 422 error.
        console.log('POST to create new Subject:', mongoSubject);
        const result = await axios.post(url, mongoSubject);
        if(result.data){
          return result.data;
        }    
      }else{
        const url = `${cpapApiEndpoint}subjects/${subjectIdToUpdate}`;
        console.log('PUT to update subject:',mongoSubject);
        const result = await axios.put(url, mongoSubject);
        if(result.data){
          return result.data;
        }   
      }
  
      return mongoSubject;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return mongoSubject;  
  }

  export async function setDeviceInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
    deviceIdToUpdate:string|undefined,
    propUpdates:Partial<DeviceSchemaProps>):Promise<Partial<DeviceSchemaProps>>{
    if(!cpapApiEndpoint || !currentFirebaseUser) return {};
    console.log('Saving device id', deviceIdToUpdate);
    console.log('data?', propUpdates);
   
    let mongoDevice:Partial<DeviceSchemaProps> = {...propUpdates};
    if(deviceIdToUpdate === undefined){
      mongoDevice = {...emptyDeviceMongoSchema, ...propUpdates};
    }
  
    const {uid, bearerToken } = currentFirebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    // Doing a post of an entity with a mongodb id will do the update
    // if not a new one is created.
  
    try{
      if(deviceIdToUpdate === undefined){
        const url = `${cpapApiEndpoint}devices`;
        // make sure the mongoUser has all the required properties or
        // the api will return a 422 error.
        console.log('POST to create new Device:', mongoDevice);
        const result = await axios.post(url, mongoDevice);
        if(result.data){
          return result.data;
        }    
      }else{
        const url = `${cpapApiEndpoint}devices/${deviceIdToUpdate}`;
        console.log('PUT to update subject:',mongoDevice);
        const result = await axios.put(url, mongoDevice);
        if(result.data){
          return result.data;
        }   
      }
  
      return mongoDevice;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return mongoDevice;  
  }


  export async function setCpapEventInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
    eventIdToUpdate:string|undefined,
    propUpdates:Partial<CpapEventSchemaProps>):Promise<Partial<CpapEventSchemaProps>>{
    if(!cpapApiEndpoint || !currentFirebaseUser) return {};
    console.log('Saving id', eventIdToUpdate);
    console.log('data?', propUpdates);
  
    let mongoEvent:Partial<CpapEventSchemaProps> = {...propUpdates};
    if(eventIdToUpdate === undefined){
      mongoEvent = {...emptyCpapEventMongoSchema, ...propUpdates};
    }
  
    const {uid, bearerToken } = currentFirebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    // Doing a post of an entity with a mongodb id will do the update
    // if not a new one is created.
  
    try{
      if(eventIdToUpdate === undefined){
        const url = `${cpapApiEndpoint}cpapevents`;
        console.log('POST to create new CpapEvent:', mongoEvent);
        const result = await axios.post(url, mongoEvent);
        if(result.data){
          return result.data;
        }   
      }else{
        const url = `${cpapApiEndpoint}cpapevents/${eventIdToUpdate}`;
        console.log('PUT to update event:',mongoEvent);
        const result = await axios.put(url, mongoEvent);
        if(result.data){
          return result.data;
        }   
      }
  
      return mongoEvent;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return mongoEvent;  
  }

  export async function setWiggleEventInfo(currentFirebaseUser:FirebaseUserProps|undefined, 
    eventIdToUpdate:string|undefined,
    propUpdates:Partial<WiggleEventSchemaProps>):Promise<Partial<WiggleEventSchemaProps>>{
    if(!cpapApiEndpoint || !currentFirebaseUser) return {};
    console.log('Saving id', eventIdToUpdate);
    console.log('data?', propUpdates);
  
    let mongoEvent:Partial<WiggleEventSchemaProps> = {...propUpdates};
    if(eventIdToUpdate === undefined){
      mongoEvent = {...emptyWiggleEventMongoSchema, ...propUpdates};
    }
  
    const {uid, bearerToken } = currentFirebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);
    // Doing a post of an entity with a mongodb id will do the update
    // if not a new one is created.
  
    try{
      if(eventIdToUpdate === undefined){
        const url = `${cpapApiEndpoint}wiggleevents`;
        console.log('POST to create new WiggleEvent:', mongoEvent);
        const result = await axios.post(url, mongoEvent);
        if(result.data){
          return result.data;
        }   
      }else{
        const url = `${cpapApiEndpoint}wiggleevents/${eventIdToUpdate}`;
        console.log('PUT to update event:',mongoEvent);
        const result = await axios.put(url, mongoEvent);
        if(result.data){
          return result.data;
        }   
      }
  
      return mongoEvent;
    }catch(error){
      console.log('API ERROR:',error);
    }
    return mongoEvent;  
  }