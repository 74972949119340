import { firebaseStorage } from "../firebaseConfig";
import {Dialog, PromptResult} from '@capacitor/dialog';
import {Toast} from '@capacitor/toast';
import { CpapEventProps, CpapEventForTable, UserProps, WiggleEventProps, WiggleEventForTable } from "./entityUtilities";
import { getDeviceDetails, getSubjectFromId } from "./apiGetUtilities";

export const showDialog = async(title:any,message:any,oktitle:any, canceltitle:any)=> {
const {value} = await Dialog.confirm({
    title: title,
    message: message,
    okButtonTitle: oktitle,
    cancelButtonTitle: canceltitle
});

return value;

};

export const showAlert = async (title: any, message:any, buttonTitle: any):Promise<boolean> =>{
await Dialog.alert( {title: title, message: message, buttonTitle: buttonTitle} );
return true;
};
  
export const showPrompt = async (title: any, message: any): Promise<PromptResult> => {

const {value, cancelled} = await Dialog.prompt({
    title: title,
    message: message
});
const result: PromptResult = {value,cancelled};

return result;

};


export const showToast = async (msg: string) => {

    console.log('Showing toast');
    await Toast.show({
      text: msg
    });
  };

export const prepareCpapEventForTable = async (user: UserProps, data: CpapEventProps[]): Promise<CpapEventForTable[]> => 
{

  console.log('[prepareCpapEventForTable] ', data?.length );
  const outputData: CpapEventForTable[] = [];

  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    console.log('element:', element);
    console.log('cpapevent: Get subjectfromid:', element?.subjectId);
    const subject = await getSubjectFromId(user, element?.subjectId);
    let newEntry: CpapEventForTable = {...element, patientIdentifier:'', org:''};
    if (subject?.patientIdentifier){
      newEntry.patientIdentifier = subject.patientIdentifier;
    }
    // get org
    const device = await getDeviceDetails(user, element?.deviceId);
    if (device?.org){
      newEntry.org = device.org.name;
    }
    outputData.push( newEntry );

    
  }

  console.log('event data for table:', outputData);
  return outputData;

}

export const prepareWiggleEventForTable = async (user: UserProps, data: WiggleEventProps[]): Promise<WiggleEventForTable[]> => 
{

  const outputData: WiggleEventForTable[] = [];

  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    console.log('wiggle event: Get subjectfromid:', element?.subjectId);
    const subject = await getSubjectFromId(user, element?.subjectId);
    let newEntry: WiggleEventForTable = {...element, patientIdentifier:'', org:''};
    if (subject?.patientIdentifier){
      newEntry.patientIdentifier = subject.patientIdentifier;
    }
    // get org
    const device = await getDeviceDetails(user, element?.deviceId);
    if (device?.org){
      newEntry.org = device.org.name;
    }
    outputData.push( newEntry );

    
  }

  console.log('event data for table:', outputData);
  return outputData;

}