import axios from 'axios';

export function getCpapApiEndpoint():string|undefined{
  let scoreitApiEndpoint = process.env.REACT_APP_CPAP_API;
  if (!scoreitApiEndpoint || scoreitApiEndpoint==='LOCAL_FILE') {
    // console.log('Getting local api endpoint');
    // scoreitApiEndpoint = 'http://127.0.0.1:3000';// hmmm which to choose.
    scoreitApiEndpoint = undefined;
  }
  return scoreitApiEndpoint;
}
// let scoreitApiEndpoint = process.env.REACT_APP_SCOREIT_API;
// if (!scoreitApiEndpoint) {
//   console.log('Getting local api endpoint');
//   scoreitApiEndpoint = 'http://127.0.0.1:3000';
// }
// export function getScoreitApiEndpoint(){
//   return scoreitApiEndpoint;
// }
// I think this can be set globally, and it only needs to be set once, like in a 
// config. For now just repeate this before every call. dlp
export function setAxiosDefaultHeaders(uid:string, bearerToken:string):void{
  axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearerToken}`,
    'uid': uid
  };
}