import React, {useEffect, useState} from 'react';
import { Button , FormControlLabel, MuiThemeProvider, TextField, createMuiTheme} from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useDeviceListState } from '../../../context/DeviceListContext';
import { DeviceProps } from '../../../common/entityUtilities';
import { useSubjectState } from '../../../context/UserContext';
import { useUserState } from '../../../context/UserContext';
import { createTheme } from '@mui/material';

import { ThemeProvider } from '@material-ui/core/styles';
import { muiDataTableTheme } from '../../../themes/default';

export function DeviceHistoryTable(props) {
    const getMuiTheme = () =>
        createTheme({
        overrides: {
            MUIDataTableHeadCell: {
                styleOverrides: {
                fixedHeaderCommon: { backgroundColor: "blue" },
                },
            },
            MUIDataTableBodyCell: {
            root: {
                backgroundColor: "#FF0000",
            },
            },
            MUIDataTablePagination: {
            root: {
                backgroundColor: "#000",
                color: "#fff",
            },
            },
        },
    });

  const initialColumns = [
    {
      name:'deviceId',
      label:'Device ID',
      options: { }
    },
    {
      name:'patientIdentifier',
      label:'Patient',
      options: { }
    },  
    {
        name:'deviceLocation',
        label:'Device Location',
        options: { }
    },
    {
      name:'updatedAt',
      label:'Update Time',
      options:{
        customBodyRender: (value, tableMeta, updateValue) => (
          new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString('en-US')
        )}
    },
  ];
  const {displayDeviceSnapshot, initialAuthLevelFilter, allHistory} = props;
  const activeUser = useUserState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);

  const rowClick = (rowData, rowMeta) => {
    console.log('Row click', tableData[rowMeta.dataIndex]);
    displayDeviceSnapshot(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
   
  };

  useEffect(() =>{
    console.log('Setting up all history for device:', allHistory);
    let toDisplay = allHistory;
    const currentAuthLevel = activeUser.authLevel;
    if(currentAuthLevel <=0){
      // hopefully this is never undefined
      toDisplay = null;
    }
    console.log('Setting table to display:', toDisplay);
    setTableData(toDisplay);
  }, [allHistory]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'patientIdentifier',
            label:'Patient ID',
            options: { }
          },
          {
            name:'dob',
            label:'Date of Birth',
            options: {
              customBodyRender: (value, tableMeta, updateValue) => (
                <FormControlLabel
                  label=""
                  value={new Date(value).toLocaleDateString}
                  control={<TextField value={new Date(value).toLocaleDateString} />}
                  onChange={event => updateValue(event.target.value)}
                />
              )
             }
          },    
          {
            name:'updatedAt',
            label:'Date of update',
            options:{}
          },  
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
        <MUIDataTable
        columns={columns}
        data={tableData}
        options={options}
        title={'Device History'}
        />
    </ThemeProvider>
  );
}