

import React from 'react';
// import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {resetPassword} from '../../../context/UserContext';

import {
  FormControl,
  Input,
  InputLabel,
  Button,
  Paper,
  Typography
} from '@mui/material';

function ResetPassword() {
    return (
      <Paper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>
        <form>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email" name="email" autoComplete="email" autoFocus />
          </FormControl>
          <Button
            component={Link}
            to="/"
            fullWidth
            variant="contained"
            color="primary"
            mt={2}
          >
            Reset password
          </Button>
        </form>
      </Paper>
    );
  }
  
  export default ResetPassword;
  