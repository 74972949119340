/* eslint-disable react/no-multi-comp */
import {useState, useEffect, React} from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

import {
  // Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography
} from '@material-ui/core';

import { spacing } from '@material-ui/system';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import {Capacitor} from '@capacitor/core';
import useStyles from './styles';

// const NavLink = React.forwardRef((props, ref) => (
//   <RouterNavLink innerRef={ref} {...props} />
// ));

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Code = styled.pre`
  margin-bottom: 0;
`;

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;


function Introduction() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom my={2}>
         Kiwi Alert monitors the CPAP status via vibrations and audio signals. 
          <br></br>
          <br></br>
        </Typography>
      </CardContent>
    </Card>
  );
}

function Contents() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          How to use this dashboard
        </Typography>
        <Typography variant="body2" gutterBottom my={2}>
          
          <b>Home -- </b> This is the home page where you will see a summary of the data
          collected and up-to-date status of your various Kiwi devices.
          <br></br>
       
          
          <b>Charts -- </b>Charts of device status and metrics will be available on this page.
          <br></br>
          <br></br>
          The menu options are shown below.
        </Typography>

        <Code>{`
  ├── Home
  ├── Settings & Tasks/
  │   ├── User Settings
  ├── Charts/
  │   ├── Device Status
  │   ├── Device Performance
  ├
  ├── About`}
      </Code>
      </CardContent>
    </Card>
  );
}

function QuickStart() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Quick start
        </Typography>
        

        <Typography variant="body2" gutterBottom my={4}>
          You can view instructions on {' '}
          <Link href="https://youtu.be/C7xtMcyHmQ4" target="_blank">
            how best to use the Kiwi dashboard.
          </Link>{' '}
          

          

        </Typography>
      </CardContent>
    </Card>
  );
}


function Changelog() {

  const [currentPackageDesc, setCurrentPackageDesc] = useState('');


  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Changelog
        </Typography>
        <Chip color="secondary" label="v1.0" /> – Jan 15, 2023
        
        <ul>
          <li>Released to production</li>
        </ul>
        <Divider my={3}></Divider>
        <Typography variant='body1'>
          {currentPackageDesc}
        </Typography>
        {/* {(isIOS || isAndroid) &&
        <Button variant='outlined' color='primary' 
          disabled
          onClick={handleUpdateApp}>
          Update App
        </Button> } */}
      </CardContent>
    </Card>
  );
}

function Documentation() {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Typography variant='h3' gutterBottom>
        About Project Kiwi
      </Typography>

      <Changelog />

      <Typography variant="h3" gutterBottom display="inline">
        Documentation
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Documentation</Typography>
      </Breadcrumbs> */}

      <Divider my={3} />

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Introduction />
          <QuickStart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Contents />
        </Grid>
      </Grid>
    </div>
  );
}

export default Documentation;
