import React, {useEffect, useState, useRef} from 'react';
import { Button } from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useUserListState } from '../../../context/UserListContext';
import { UserProps } from '../../../common/entityUtilities';
import { useUserState } from '../../../context/UserContext';
import { Grid } from '@mui/material';

import { ThemeProvider } from '@material-ui/core/styles';
import { muiDataTableTheme } from '../../../themes/default';
export function UserInformationTable(props) {

  const {editOrCreateUser, initialAuthLevelFilter} = props;
  const activeUser = useUserState();
  const { allUsers } = useUserListState();
  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef(tableData);
  const [selectedRow, setSelectedRow] = useState(0);
  const selectedRowRef = useRef(selectedRow);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Row click ', rowMeta);
    setSelectedRow(rowMeta.dataIndex);
    editOrCreateUser(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const handleEditUser = ()=>{
    console.log('tableData:', tableDataRef.current);
    console.log('selected row:', selectedRowRef.current);
    editOrCreateUser(tableDataRef.current[selectedRowRef.current]);
  }

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // customToolbar: () => {
    //   return (
    //     <Button 
    //       color="primary"
    //       onClick={()=> {editOrCreateUser(null);}}
    //       variant="outlined"
    //     >
    //       Add new
    //     </Button>
    //     // <CustomToolbar />
    //   );
    // }
  };



  useEffect(() =>{
    console.log('Setting up all users:', allUsers);
    console.log('initialAuthLevelFilter:', initialAuthLevelFilter);
    let toDisplay = allUsers;
    const currentAuthLevel = activeUser.authLevel;
    if(currentAuthLevel <=0){
      // hopefully this is never undefined
      toDisplay = allUsers.filter(user=>user.userId === activeUser.userId);
    }else if(currentAuthLevel <=5){
      // this will get non registered users too. When a proper family structure gets setup
      // this should not be a problem.
      toDisplay = allUsers.filter(user=>user.authLevel<5);
    }
    setTableData(toDisplay);
  }, [allUsers]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'firstname',
            label:'First Name',
            options: { }
          },
          {
            name:'lastname',
            label:'Last Name',
            options: { }
          },    
          {
            name:'localEmail',
            label:'Email',
            options:{}
          },  
          {
            name:'action',
            label:'Commands',
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                console.log('action button');
                return (
                  <Button color='primary'>Edit</Button>
                )
              }
            }
          },
          {
            name:'authLevel',
            label:'Status',
            options:{
              display:false,
              filterList:[initialAuthLevelFilter],
              customFilterListOptions: { render: v => `Status: ${v}` },
            }
          },
        ]);
    }
  }, [initialAuthLevelFilter]);


  const initialColumns = [
    {
      name:'firstname',
      label:'First Name',
      options: { }
    },
    {
      name:'lastname',
      label:'Last Name',
      options: { }
    },    
    {
      name:'localEmail',
      label:'Email',
      options:{}
    },  
    // {
    //   name:'action',
    //   label:'Actions',
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Grid container>
    //           <Grid item>
    //             <Button color='primary' variant='outlined'
    //               onClick={()=>{
    //                 setTableData( value => {
    //                   console.log('Table data:', value);
    //                   console.log('Meta:', tableMeta);
    //                   editOrCreateUser(value[tableMeta.rowIndex]);
    //                   return value;
    //                 });
    //                 }}>
    //                 Edit
    //               </Button>
    //           </Grid>
    //           <Grid item>
    //             <Button color='primary' variant='outlined'
    //               onClick={()=>{
    //                 console.log('History');
                   
    //               }
    //               }>
    //                 History
    //               </Button>
    //           </Grid>
    //         </Grid>
    //       )
    //     }
    //   }
    // },
    {
      name:'authLevel',
      label:'Status',
      options:{
        display:false,
        customFilterListOptions: { render: v => `Status: ${v}` },
      }

    },
  ];
  const [columns, setColumns] = useState(initialColumns);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
    <MUIDataTable
      columns={columns}
      data={tableData}
      options={options}
      title={'Kiwi Users'}
    /></ThemeProvider>
  );
}