import React, {useState, useEffect} from 'react';

// import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // Divider,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  // FormControl,
  Grid,
  FormGroup,
  FormControl,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {  DateTimePicker } from '@material-ui/pickers';
import {
  emptyCpapEventState, WiggleEventProps, VentilationMode, SubjectProps, emptySubjectState,
} from '../common/entityUtilities';



import { useWiggleEventListState, useWiggleEventListDispatch, WiggleEventListTypes } from '../context/WiggleEventListContext';

import { useUserDispatch, useUserState } from '../context/UserContext';

import {
  setWiggleEventInfo,
  setSubjectInfo
} from '../common/apiSetUtilities';


import { 
  SubjectSchemaProps
} from '../common/mongoSchemas';
import { useSubjectListState } from '../context/SubjectListContext';
import { getSubject } from '../common/apiGetUtilities';

type UIPropsForNewEventDialog = {

  _id?: string;
  amplitude: number;
  deviceId: string;
  subjectId: string;
  frequency: number;
  map: number;
  fio2: number;
  spo2: number;
  pco2: number;
  tco2: number;

  itime: number;
  rate: number;
  pip: number;
  peep: number;
  comments: string;
  ventilation: string;
  position: string;
  chest_wiggle: string;
  updatedAt: Date;
};

const emptyEventUIState:UIPropsForNewEventDialog = {
  

  _id: undefined,
  amplitude: 0,
  deviceId: '',
  subjectId: '',
  frequency: 0,
  map: 0,
  fio2: 0,
  spo2: 0,
  pco2: 0,
  tco2:0,

  itime: 0,
  rate: 0,
  pip: 0,
  peep: 0,
  comments: '',
  ventilation: '',
  position: 'supine',
  chest_wiggle: 'not recorded',
  updatedAt: new Date()
};

export type EventInfoDialogProps = {
  isOpen:boolean,
  deviceId: string,
  currentSubjectForDevice: any,
  dialogComplete:()=>void,
  eventInformation:WiggleEventProps|null

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        width: 200,
      },
    },
  }),
);

export const WiggleEventInfoDialog = (props:EventInfoDialogProps):JSX.Element=> {
  const classes = useStyles();
  // userInformation is UserProps
  const {isOpen, dialogComplete, eventInformation, deviceId, currentSubjectForDevice}  = props;
  
  const userState = useUserState();
  const eventListDispatch = useWiggleEventListDispatch();
  const {allSubjects} = useSubjectListState();

  const [dialogValue, setDialogValue] = useState(emptyEventUIState);
  const [displayEventInformation, setDisplayEventInformation] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(emptySubjectState);
  // this can probably be replaced by the emmaUserId
  const [isNewEvent, setIsNewEvent] = useState(true);
  const [ventilation, setVentilation] = useState("");
  const [position, setPosition] = useState("");
  const [chest_wiggle, setChestWiggle] = useState('');
  

  const [errors, setErrors] = useState<any>({});

  const allVentilation = ['HFOV', 'HFJV', 'Bubble'];
  const allPositions = ['Supine', 'Prone', 'LLD', 'RLD'];
  const allWiggles = ['Not recorded', 'Poor', 'Moderate', 'Good'];
  


  useEffect(()=>{
    
    if(eventInformation === null){
      console.log('Dialog reset');
      return;
    }
    if(eventInformation?.subjectId == ''){
      console.log('Logging new event with device:',deviceId);
      setDisplayEventInformation(true);
      setIsNewEvent(true);
      setSelectedSubject(currentSubjectForDevice);
      setDialogValue({...emptyEventUIState, deviceId: deviceId});
      setVentilation("");
      console.log('Dialog values:', {...emptyEventUIState, deviceId: deviceId});
      return;      
    }

    setDisplayEventInformation(false);
    setIsNewEvent(false);

    setVentilation(eventInformation?.ventilation);
    setPosition(eventInformation?.position);
    setChestWiggle(eventInformation?.chest_wiggle);

    console.log('eventInformation in [useEffect]:', eventInformation);
    let dialogValues:UIPropsForNewEventDialog =  {...emptyEventUIState, 
      _id: eventInformation._id,
        amplitude: eventInformation.amplitude,
        deviceId: eventInformation.deviceId,
        subjectId: eventInformation.subjectId,
        frequency: eventInformation.frequency,
        map: eventInformation.map,
        fio2: eventInformation.fio2,
        spo2: eventInformation.spo2,
        pco2: eventInformation.pco2,
        tco2:eventInformation.tco2,
        itime: eventInformation.itime,
        rate: eventInformation.rate,
        pip: eventInformation.pip,
        peep: eventInformation.peep,
        comments: eventInformation.comments,
        ventilation: eventInformation.ventilation,
        position: eventInformation.position,
        chest_wiggle: eventInformation.chest_wiggle,
        updatedAt: eventInformation.updatedAt
      
    };
    console.log('Setting dialogValues:', dialogValues);
    setDialogValue(dialogValues);
    

    if (eventInformation?.subjectId){
      // event has an associated subject
      const subject = allSubjects.filter(x=>x._id==eventInformation.subjectId);
      if (subject.length > 0){
        setSelectedSubject(subject[0]); //ideally only one item passes the above test
        console.log('Setting selected subject to:', subject[0]);
      }
    }

    console.log('Current Subject:', currentSubjectForDevice);
   
  },[eventInformation]);
  
  useEffect( ()=>{
    console.log('Ventilation:', ventilation);
  },[ventilation]);

  useEffect( ()=>{
    console.log('Position:', position);
    allPositions.map( (option) => {
      if (option.toLocaleLowerCase() == position.toLocaleLowerCase()){
        setPosition(option);
      }
    })
  },[position]);


  useEffect( ()=>{
    console.log('chest_wiggle:', chest_wiggle);
    allWiggles.map( (option) => {
      if (option.toLocaleLowerCase() == chest_wiggle.toLocaleLowerCase()){
        setChestWiggle(option);
      }
    })
  },[chest_wiggle]);

  const updateUIWithValues = (createNewEvent: boolean, eventId: string | undefined, 
    eventPropertiesMongoDb: Partial<WiggleEventProps>) =>{

    let newPropsForEvent = eventPropertiesMongoDb;
    if(createNewEvent){
      const newEvent = {...emptyEventUIState, ...newPropsForEvent };
      console.log('Dispatch create new event:', newEvent);
      eventListDispatch({type:WiggleEventListTypes.ADD_EVENT, allWiggleEvents:[newEvent]});
    }else{
      // updating an event that exists
      console.log('Dispatch Updating with', newPropsForEvent);
      eventListDispatch({type:WiggleEventListTypes.UPDATE_EVENT, allWiggleEvents:[newPropsForEvent]});
    
    }
  };
  


  const handleClose = () => {
    // new way of reseting dialog state when closing.
    setErrors({});
    setDialogValue(emptyEventUIState);
    console.log('Closing dialog');
    dialogComplete();
  };


  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('[handleSubmit] dialogValue:', dialogValue);
    console.log('[handleSubmit] eventInformation:', eventInformation);



    // Copied from UserSettings component
    const mongoEventValuesToUpdate:Partial<WiggleEventProps> = {
      
        amplitude: dialogValue.amplitude,
        deviceId: dialogValue.deviceId,
        subjectId: selectedSubject && selectedSubject._id,
        frequency: dialogValue.frequency,
        map: dialogValue.map,
        fio2: dialogValue.fio2,
        spo2: dialogValue.spo2,
        pco2: dialogValue.pco2,
        tco2:dialogValue.tco2,
        itime: dialogValue.itime,
        rate: dialogValue.rate,
        pip: dialogValue.pip,
        peep: dialogValue.peep,
        comments: dialogValue.comments,
        ventilation: dialogValue.ventilation,
        position: dialogValue.position,
        chest_wiggle: dialogValue.chest_wiggle,
        updatedAt: dialogValue.updatedAt
    };//

    
  

    // The user that is saving things
    const firebaseUser = userState.firebaseUser;
    // hmm wont this naturally be undefined?
    let eventToUpdateId = eventInformation?._id;
    if(isNewEvent) eventToUpdateId = undefined;

    setWiggleEventInfo(firebaseUser, eventToUpdateId, mongoEventValuesToUpdate)
      .then(storedUserData=>{ // Stored data is CpapEventSchemaProps
        updateUIWithValues(isNewEvent, eventToUpdateId, storedUserData);
        
      }).finally( ()=> { handleClose();});
   // handleClose();
  };

  //Ugh, this UI should to be split/updated for two components, one for user and the
  // other for parents
  return (

    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">          
          {eventInformation?'Update event':'Create event'}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <Typography
              color="secondary"
              variant="h6"
            >
                Event Info
            </Typography>

            <TextField
              autoFocus
              label="Time stamp"
              value={dialogValue?.updatedAt && 
                new Date(dialogValue?.updatedAt).toLocaleDateString('en-us') + ", " + 
                new Date(dialogValue?.updatedAt).toLocaleTimeString('en-us')}/>

          <DateTimePicker
              label="Change event time"
              margin="normal"
              onChange={(date: Date|null)=>{date && setDialogValue({...dialogValue, updatedAt:date})}}
              value={dialogValue?.updatedAt}
            />
            
           
            
            <TextField
            autoFocus                
            id="amplitude"
            label="Amplitude"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, amplitude: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.amplitude}
            />
            <TextField
            autoFocus                
            id="frequency"
            label="Frequency"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, frequency: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.frequency}
            />
            <TextField
            autoFocus                
            id="map"
            label="MAP"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, map: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.map}
            />


            <TextField
            autoFocus                
            id="fio2"
            label="FiO2"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, fio2: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.fio2}
            />
            <TextField
            autoFocus                
            id="spo2"
            label="SpO2"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, spo2: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.spo2}
            />
            <TextField
            autoFocus                
            id="tco2"
            label="TCO2"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, tco2: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.tco2}
            />



        <Autocomplete
            freeSolo
            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
            id='notes-autocomplete'
            value={dialogValue.comments}
            options={['Suctioning', 'ETT Change', 'Surfactant']}
            disableClearable
            onChange={ (event,value) => {
              setDialogValue({...dialogValue, comments: value});
            }}
            renderInput={ (params) => (
              <TextField {...params}
              fullWidth
              label="Notes"
              onChange={(event)=> {
                setDialogValue({...dialogValue, comments: event.target.value});
              }}
              InputProps={ {
                ...params.InputProps,
                type:'search'
              }}/>
            )}      
          />



          <FormControl fullWidth>
            <InputLabel >Ventilation Mode</InputLabel>
            <Select 
                id="ventilation"
                label="Ventilation Mode"
                defaultValue=""
                value={ventilation && ventilation}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, ventilation: event.target.value});
                  setVentilation(event.target.value);
                  console.log('Current mode:', ventilation);
                  console.log('Setting ventilation:', event.target.value);
                  }
                }
              >
                {allVentilation && allVentilation.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected = {ventilation == option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl fullWidth>
            <InputLabel >Infant Position</InputLabel>
            <Select 
                id="position"
                label="Infant Position"
                defaultValue=""
                value={position && position}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, position: event.target.value});
                  setPosition(event.target.value);
                  console.log('Current position:', position);
                  console.log('Setting position:', event.target.value);
                  }
                }
              >
                {allPositions && allPositions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected = {position == option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl fullWidth>
            <InputLabel >Chest Wiggle Factor</InputLabel>
            <Select 
                id="chest_wiggle"
                label="Chest Wiggle Factor"
                defaultValue=""
                value={chest_wiggle && chest_wiggle}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, chest_wiggle: event.target.value});
                  setChestWiggle(event.target.value);
                  console.log('Current wiggle:', chest_wiggle);
                  console.log('Setting wiggle:', event.target.value);
                  }
                }
              >
                {allWiggles && allWiggles.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    selected = {chest_wiggle == option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel >Choose subject</InputLabel>
              <Select 
                  id="subject"
                  label="Subject"
                  disabled={true}
                  value={selectedSubject && selectedSubject.patientIdentifier}
                  // m={2}
                  onChange={ async (event:any) => {
                      setDialogValue({ ...dialogValue, subjectId: event.target.value});
                      console.log('Current subject:', selectedSubject);
                      console.log('Chosen:', event.target);
                      const subject = await getSubject(userState, event.target.value);
                      console.log('Setting subject to:', subject);
                      setSelectedSubject(subject && subject[0]);
                    }
                  }
                >
                  {allSubjects && allSubjects.map((option) => (
                    <MenuItem
                      key={option._id}
                      value={option.patientIdentifier}
                    >
                      {option.patientIdentifier}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </div>         

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
              Cancel
          </Button>
          <Button
            color="primary"
            disabled={Object.keys(errors).length > 0 || !dialogValue.deviceId}
            type="submit"
          >
            {eventInformation? 'Update':'Create'}              
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
