import React from 'react';
//import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserContext';
import { UserListProvider } from './context/UserListContext';
import { LayoutProvider } from './context/LayoutContext';
import {ThemeProvider} from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { SubjectListProvider } from './context/SubjectListContext';
import { CssBaseline } from '@material-ui/core';
import { SharedSnackbarProvider } from './context/SnackbarContext';
import Themes from './themes';
import { CpapEventListProvider } from './context/CpapEventListContext';
import { DeviceListProvider } from './context/DeviceListContext';
//const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();
//root.render(
  //<React.StrictMode>
  ReactDOM.render(
    <LayoutProvider>
      <UserProvider>
        <UserListProvider>
          <SubjectListProvider>
            <DeviceListProvider>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <SharedSnackbarProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
                </MuiPickersUtilsProvider>
              </SharedSnackbarProvider>
            </ThemeProvider>
          </DeviceListProvider>
          </SubjectListProvider>
        </UserListProvider>
      </UserProvider>
    </LayoutProvider>,
    document.getElementById('root'),
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
