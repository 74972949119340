import { makeStyles } from '@material-ui/styles';
export default makeStyles(theme => ({
  wrapper: {
    transparency:'50%',
    justify:'center',
    padding: theme.spacing(3),
    width: '100vw',
    height: '100vh',
    spacing: 0,
    margin:0,
    // paddingTop: `env(safe-area-inset-top)`,
    // paddingBottom: `env(safe-area-inset-bottom)`,
    // paddingLeft: `env(safe-area-inset-left)`,
    // paddingRight: `env(safe-area-inset-right)`,
    // justify: 'space-around',
    background: 'url(/images/em_background2.jpg) no-repeat top center fixed',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10)
    }
  },   
  googleButton: {
    marginTop: theme.spacing(1),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    fullWidth:false,
    size:'small',
    paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(3),
    textTransform: 'none',
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
}));
// import { makeStyles } from '@mui/styles';
// import { useTheme} from '@mui/material/styles';

// export default makeStyles(theme => ({
//   wrapper: {
//     transparency:'50%',
//     justify:'center',
//     padding: useTheme().spacing(3),
//     width: '100vw',
//     height: '100vh',
//     spacing: 3,
//     margin:3,
//     background: 'url(/images/em_background2.jpg) no-repeat top center fixed',
//     [useTheme().breakpoints.up('md')]: {
//       padding: useTheme().spacing(8)
//     }
//   },   
//   googleButton: {
//     marginTop: useTheme().spacing(1),
//     backgroundColor: 'white',
//     fullWidth:false,
//     size:'small',
//     paddingLeft:useTheme().spacing(3),
//     paddingRight:useTheme().spacing(),
//     textTransform: 'none',
//   },
//   googleIcon: {
//     width: 30,
//     marginRight: useTheme().spacing(2),
//   },
// }));