import React, {useEffect, useState} from 'react';
import { Button, TextField, ThemeProvider } from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useUserState } from '../../context/UserContext';
import { useCpapEventListState } from '../../context/CpapEventListContext';
import { emptyCpapEventState } from '../../common/entityUtilities';
import { getSubjectFromId } from '../../common/apiGetUtilities';
import { CpapEventProps } from '../../common/entityUtilities';
import { prepareCpapEventForTable } from '../../common/utilities';

import { muiDataTableTheme } from '../../themes/default';


export function CpapEventInformationTable(props) {

  const initialColumns = [
    {
      name:'patientIdentifier',
      label:'Patient Id',
      options: { 
      }
    },
    {
      name:'position',
      label:'Patient Position',
      options: { }
    },  
    {
      name:'updatedAt',
      label:'Timestamp',
      options:{

        customBodyRender: (value, tableMeta, updateValue) => (

          new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString('en-US')
        )
      }
    },
    {
      name:'authLevel',
      label:'Status',
      options:{
        display:false,
        customFilterListOptions: { render: v => `Status: ${v}` },
      }

    },
  ];

  const patientIdentifierFromId = async (id) => {
    const subject = await getSubjectFromId(activeUser, id);
    console.log('[subject] ', subject);
    return subject?.patientIdentifier;
  }

  const {editOrCreateCpapEvent, initialAuthLevelFilter, selectedDevice} = props;
  const activeUser = useUserState();
  const { allCpapEvents, deviceId, setDeviceId } = useCpapEventListState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Row click', rowMeta);
    editOrCreateCpapEvent(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          color="primary"
          onClick={()=> {
            const eventInfo = emptyCpapEventState;
            editOrCreateCpapEvent({...eventInfo, deviceId: selectedDevice});
          }}
          variant="outlined"
        >
          Log Event
        </Button>
        // <CustomToolbar />
      );
    }
  };

  useEffect ( ()=> {
    if (setDeviceId && selectedDevice){
        console.log('Setting selected device:', selectedDevice);
        setDeviceId(selectedDevice);
    }
  }, [selectedDevice]);

  useEffect(() =>{
    const localFn = async()=>{

        console.log('Setting up all cpap events:', allCpapEvents);
        console.log('Selected device:', selectedDevice);
        console.log('activeUser:', activeUser);
        console.log('await on prepareCpapEventForTable');
        const dataForTable = await prepareCpapEventForTable(activeUser, allCpapEvents);
        let toDisplay = dataForTable; //allCpapEvents;
        console.log('cpapevents ToDisplay:', toDisplay);
        const currentAuthLevel = activeUser.authLevel;
        if(currentAuthLevel <=0){
          // hopefully this is never undefined
          toDisplay = null;
        }
        console.log('Setting table to display:', toDisplay);
        setTableData(toDisplay);
      }
    

    localFn();
    
  }, [allCpapEvents]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'patientIdentifier',
            label:'Patient Id',
            options: { }
          },
          {
            name:'position',
            label:'Patient Position',
            options: { }
          },    
          {
            name:'updatedAt',
            label:'Timestamp',
            options:{}
          },  
          {
            name:'authLevel',
            label:'Status',
            options:{
              display:false,
              filterList:[initialAuthLevelFilter],
              customFilterListOptions: { render: v => `Status: ${v}` },
            }
          },
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
    <MUIDataTable
      columns={columns}
      data={tableData}
      options={options}
      title={'Cpap Events'}
    />
    </ThemeProvider>
  );
}