import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  Typography as MuiTypography,
  Box
} from '@material-ui/core';

// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { spacing } from '@material-ui/system';

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)`
  background: ${props => props.background};
  color: ${props => props.color};
  margin-bottom: 2px;
`;
// margin-bottom: ${props => props.theme.spacing(3)}px;

const CardContent = styled(MuiCardContent)`
  position: relative;
  margin: 2px;

  &:last-child {
    padding-bottom: 4px;
  }
`;
// margin: ${props => props.theme.spacing(2)}px;
// padding-bottom: ${props => props.theme.spacing(4)}px;

const IconWrapper = styled.button`
  background:transparent;
  height:48px;
  width:48px;
  position: absolute;
  right: 16px;
  top: 32px;
  border-radius: 50%;

  border: 3px solid ${props=>props.color};
  // border: 2px solid ${props=>props.color}
  // svg {
  //   width: 32px;
  //   height: 32px;
  // }
  color: ${props=>props.color};
`;

function Stats({ title, id, amount, icon: Icon, color, handler: handler, linkto:cardlink, daysBack:daysBack, status: status}) {

  return (
    <Card mb={3} style={{backgroundColor:'#EFF7FF'}}>     
      <CardContent>
        <Typography
          gutterBottom
          variant="h3"
        >
          <Box fontWeight="fontWeightRegular">{amount}</Box>
        </Typography>
        <Typography
          color = "secondary"
          gutterBottom
          mb={0}
          mt={3}
          variant="h5"
        >
          {title}
        </Typography>
        <Typography variant='caption' display='block'>
          {id}
        </Typography>
        {handler && 
          <Typography
            color="primary"
            gutterBottom
            mb={0}
            mt={3}
            onClick={()=>{handler(daysBack);}}
            variant="body2"
          >
            Details
          </Typography>
        }
        <IconWrapper color={color}>
          <Icon onClick={()=>{if(handler)handler(daysBack);}}/>
        </IconWrapper>
        
      </CardContent>
    </Card>
  );
}

export default Stats;
