import React, {useEffect, useState} from 'react';
import { getAllWiggleEventsForDevice } from '../common/apiGetUtilities';
import { useUserState } from './UserContext';
import { UserProps, WiggleEventProps, convertUserDataToUserPropType, emptyUserState } from '../common/entityUtilities';
type Props = {
  children:React.ReactNode;
};
enum WiggleEventListTypes {
  LOAD = 'LOAD',
  RESET = 'RESET',
  ADD_EVENT = 'ADD_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT'
}
type WiggleEventListProps = {
  deviceId: string|null;
  allWiggleEvents:WiggleEventProps[],
  setDeviceId: object|undefined
}
interface WiggleEventListAction extends WiggleEventListProps{
  type:WiggleEventListTypes;
}

const intialSubjectList:WiggleEventListProps = {
  allWiggleEvents: [],
  deviceId: null,
  setDeviceId: undefined
};



function wiggleEventListReducer(state:WiggleEventListProps, action:WiggleEventListAction){
  switch(action.type) {
    case WiggleEventListTypes.LOAD:{
      const {type, ...noType} = action;
      return noType;
    }
    case WiggleEventListTypes.RESET:
      return { ...state, ...intialSubjectList};
    case WiggleEventListTypes.ADD_EVENT:{
      const list = state.allWiggleEvents;
      console.log('Old events:', list);
      const newEvent = action.allWiggleEvents;
      console.log('new events:', newEvent);

      const newList = list.map( (item)=> {
        return item;
      });

      const oldEventSet = new Set(list.map(item=>item._id));
      for(const event of newEvent){
        if(!oldEventSet.has(event._id)){
          console.log('Adding wiggle event', event._id);
          list.push(event);
          newList.push(event);
          // this is, unfortunately, a hack
          // if you return 'list' wiggleinformationtable does NOT update with the newly added event
        }
      }      
      const listOfSubjectProps:WiggleEventListProps = {
        allWiggleEvents:newList,
        deviceId:state.deviceId,
        setDeviceId:state.setDeviceId
      };
      console.log('event reducer, add event:', listOfSubjectProps);
      return {  ...listOfSubjectProps};
    }
    case WiggleEventListTypes.UPDATE_EVENT:{
      const origList = state.allWiggleEvents;
      const updateEventList = action.allWiggleEvents;
    
      const updatedDict = new Map();
      
      for (const event of updateEventList) {
        const {_id, ...others} = event;
        updatedDict.set(_id, others);
      }
    
      const newEventSet = new Set(updateEventList.map(item=>item._id));
      const newList = origList.map((item) => {
        const eventId = item._id;
        if (newEventSet.has(eventId)) {
          const updateValues = updatedDict.get(eventId);
          const updatedItem = {
            ...item,
            ...updateValues
          };   
          return updatedItem;
        }   
        return item;
      });  
      const listOfSubjectProps:WiggleEventListProps = {
        allWiggleEvents: newList,
        deviceId: state.deviceId,
        setDeviceId: undefined
      };
      console.log('event reducer, update event:', listOfSubjectProps);
      return {  ...listOfSubjectProps};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const WiggleEventListStateContext = React.createContext<WiggleEventListProps>(null!);
const WiggleEventListDispatchContext = React.createContext<React.Dispatch<any>>(null!);

function WiggleEventListProvider({ children }:Props) {
  const user = useUserState();
  
  //so ugly. Pretty sure the types are incorrect/all over the place
  const [deviceId, setDeviceId] = useState(null);
  const [state, dispatch] = React.useReducer(wiggleEventListReducer, 
    intialSubjectList);
    
  useEffect(()=>{
    async function loadSubject(){
      console.log('[eventcontext] user:', user);
      if(user.authLevel <0 ) return;

      console.log('eventlistcontext, deviceid:', deviceId);
      const events = await getAllWiggleEventsForDevice(user, deviceId);
      const initEvents:WiggleEventProps[] = [];
      for(const rawUser of events){
        initEvents.push(rawUser);
      }
      dispatch({ type: WiggleEventListTypes.LOAD, deviceId: deviceId,  allWiggleEvents: initEvents, setDeviceId:setDeviceId});
    }
    loadSubject();
  }, [user, deviceId]);
  return (
    <WiggleEventListStateContext.Provider value={{...state, deviceId}}>
      <WiggleEventListDispatchContext.Provider value={dispatch}>
        {children}
      </WiggleEventListDispatchContext.Provider>
    </WiggleEventListStateContext.Provider>
  );
}

function useWiggleEventListState(deviceId: string) {
  const context = React.useContext(WiggleEventListStateContext);
  if (context === undefined) {
    throw new Error('WiggleEventListStateContext must be used within a WiggleEventListProvider');
  }
  //console.log('[useWiggleeventliststate] ', context);
  return context;
}

function useWiggleEventListDispatch() {
  const context = React.useContext(WiggleEventListDispatchContext);
  if (context === undefined) {
    throw new Error('useWiggleEventListDispatch must be used within a WigglEventListProvider');
  }
  return context;
}

export { WiggleEventListProvider, useWiggleEventListState, useWiggleEventListDispatch, WiggleEventListTypes };
