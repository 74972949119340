import React, {useEffect, useState} from 'react';
import { Button , FormControlLabel, TextField} from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useDeviceListState } from '../../../context/DeviceListContext';
import { DeviceProps } from '../../../common/entityUtilities';
import { useUserState } from '../../../context/UserContext';
import { ThemeProvider } from '@material-ui/core/styles';
import { muiDataTableTheme } from '../../../themes/default';
export function DeviceInformationTable(props) {

  const initialColumns = [
    {
      name:'deviceId',
      label:'ID',
      options: { }
    },
    {
      name:'description',
      label:'Device Info',
      options: { }
    },  
    {
      name:'subjectId',
      label:'Patient Identifier',
      options:{}
    },
   
  ];
  const {editOrCreateDevice, initialAuthLevelFilter} = props;
  const activeUser = useUserState();
  const { allDevices } = useDeviceListState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Row click', rowMeta);
    editOrCreateDevice(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          color="primary"
          onClick={()=> {editOrCreateDevice(null);}}
          variant="outlined"
        >
          Add Device
        </Button>
        // <CustomToolbar />
      );
    }
  };

  useEffect(() =>{
    console.log('Setting up all devices:', allDevices);
    let toDisplay = allDevices;
    const currentAuthLevel = activeUser.authLevel;
    if(currentAuthLevel <=0){
      // hopefully this is never undefined
      toDisplay = null;
    }
    console.log('Setting table to display:', toDisplay);
    setTableData(toDisplay);
  }, [allDevices]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'subjectId',
            label:'Patient ID',
            options: { }
          },
          {
            name:'deviceId',
            label:'Device ID',
            options: {}
          },    
          {
            name:'description',
            label:'Device Info',
            options:{}
          },  
          
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
    <MUIDataTable
      columns={columns}
      data={tableData}
      options={options}
      title={'Devices'}
    />
    </ThemeProvider>
  );
}