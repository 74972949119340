import React, {useEffect} from 'react';
// import {userData} from '../common/staticdata';
import { getAllDevices } from '../common/apiGetUtilities';
import { useUserState } from './UserContext';
import { DeviceProps, emptyDeviceState } from '../common/entityUtilities';
type Props = {
  children:React.ReactNode;
};
enum DeviceListTypes {
  LOAD = 'LOAD',
  RESET = 'RESET',
  ADD_DEVICE = 'ADD_DEVICE',
  UPDATE_DEVICE = 'UPDATE_DEVICE'
}
type DeviceListProps = {
  allDevices:DeviceProps[]  
}
interface DeviceListAction extends DeviceListProps{
  type:DeviceListTypes;
}

const intialDeviceList:DeviceListProps = {
  allDevices:[]
};



function deviceListReducer(state:DeviceListProps, action:DeviceListAction){
  switch(action.type) {
    case DeviceListTypes.LOAD:{
      const {type, ...noType} = action;
      console.log('Subject Dispatch:', noType);
      return noType;
    }
    case DeviceListTypes.RESET:
      return { ...state, ...intialDeviceList};
    case DeviceListTypes.ADD_DEVICE:{
      const list = state.allDevices;
      const newDevice = action.allDevices;
      const oldDeviceSet = new Set(list.map(item=>item.deviceId));
      for(const device of newDevice){
        if(!oldDeviceSet.has(device.deviceId)){
          console.log('Adding device', device.deviceId);
          list.push(device);
        }
      }      
      const listOfDeviceProps:DeviceListProps = {
        allDevices:list
      };
      return {  ...listOfDeviceProps};
    }
    case DeviceListTypes.UPDATE_DEVICE:{
      const origList = state.allDevices;
      console.log('origlist of events:', origList);
      console.log('update:', action.allDevices);
      const updateDeviceList = action.allDevices;
    
      const deviceValueDict = new Map();
      
      for (const device of updateDeviceList) {
        const {_id, ...others} = device;
        deviceValueDict.set(_id, others);
      }
    
      const newDeviceSet = new Set(updateDeviceList.map(item=>item._id));
      const newList = origList.map((item) => {
        const deviceId = item._id;
        if (newDeviceSet.has(deviceId)) {
          const updateValues = deviceValueDict.get(deviceId);
          const updatedItem = {
            ...item,
            ...updateValues
          };   
          return updatedItem;
        }   
        return item;
      });  
      const listOfDeviceProps:DeviceListProps = {
        allDevices:newList
      };
      return {  ...listOfDeviceProps};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const DeviceListStateContext = React.createContext<DeviceListProps>(null!);
const DeviceListDispatchContext = React.createContext<React.Dispatch<any>>(null!);

function DeviceListProvider({ children }:Props) {
  const user = useUserState();
  const [state, dispatch] = React.useReducer(deviceListReducer, 
    intialDeviceList);
    
  useEffect(()=>{
    async function loadDevice(){
      console.log('[subjectlistcontext] ', user);
      if(user.authLevel <0 ) return;
      const devices = await getAllDevices(user);
      const initDevices:DeviceProps[] = [];
      for(const rawDevice of devices){
        initDevices.push(rawDevice);
      }
      dispatch({ type: DeviceListTypes.LOAD, allDevices:initDevices });
    }
    loadDevice();
  }, [user]);
  return (
    <DeviceListStateContext.Provider value={state}>
      <DeviceListDispatchContext.Provider value={dispatch}>
        {children}
      </DeviceListDispatchContext.Provider>
    </DeviceListStateContext.Provider>
  );
}

function useDeviceListState() {
  const context = React.useContext(DeviceListStateContext);
  if (context === undefined) {
    throw new Error('DeviceListStateContext must be used within a DeviceListProvider');
  }
  return context;
}

function useDeviceListDispatch() {
  const context = React.useContext(DeviceListDispatchContext);
  if (context === undefined) {
    throw new Error('useDeviceListDispatch must be used within a DeviceListProvider');
  }
  return context;
}

export { DeviceListProvider, useDeviceListState, useDeviceListDispatch,DeviceListTypes };
