import React,  { useEffect, useState }  from 'react';
import {
  // Grid,
  // Paper,
  Typography,
  Divider,
  // Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


import {UserInfoDialog} from '../../dialogs/UserInfoDialog';

import { DeviceTable } from './components/devicetable';
import { DeviceInformationTable } from './components/DeviceInformationTable';
import { DeviceDetailsDialog } from '../../dialogs/DeviceDetailsDialog';
import { getAllOrgs } from '../../common/apiGetUtilities';
import { 
  emptyDeviceState,
  DeviceProps
} from '../../common/entityUtilities';

import {useUserState} from '../../context/UserContext';
import { useDeviceListState } from '../../context/DeviceListContext';

import { getAllDevices } from '../../common/apiGetUtilities';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection:'column',
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Devices(props:any):JSX.Element{
  const initialAuthFilter = props?.history?.location?.state?.initialAuthFilter;
  const classes = useStyles();
  const activeUser = useUserState();
  const [parentCreateDialogIsOpen, setParentCreateDialogIsOpen] = useState(false);
  // null is used to reset the ui, kind of hackish...
  const [deviceToUpdate, setDeviceToUpdate] = useState<DeviceProps|null>(emptyDeviceState);

  const [allDevices, setAllDevices] = useState<any[]>([]);
  const [allOrgs, setAllOrgs] = useState<any[]>([]);

  useEffect( ()=>{
    const getDevices = async () => {
        // get all the orgs
        console.log('Active user:', activeUser);
        const orgs = await getAllDevices(activeUser);
        setAllDevices(orgs);
    };
    
    getDevices();

    return () => {
        // this now gets called when the component unmounts
    };
  }, [activeUser]);


  useEffect( ()=>{
    const getOrgs = async () => {
        // get all the orgs
        console.log('Active user:', activeUser);
        const orgs = await getAllOrgs(activeUser);
        setAllOrgs(orgs);
    };
    
    getOrgs();

    return () => {
        // this now gets called when the component unmounts
    };
  }, [activeUser]);



  const editOrCreateUser = (deviceInfo:DeviceProps|null) =>{
    //Order is important, first make visible then update user

    setParentCreateDialogIsOpen(true);
    console.log('The deviceinfo', deviceInfo);

    setDeviceToUpdate(deviceInfo);
  };

  const handleDeviceDetailsClose = ()=>{
        setParentCreateDialogIsOpen(false);
        setDeviceToUpdate(null);
    
  }
  const dialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setDeviceToUpdate(null);//reset the ui
    // setParentCreateDialogIsOpen(false);
    setParentCreateDialogIsOpen((prev)=> !prev);
  };

  // This should really be dealt with on the router level...
  if (activeUser.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }


  return (
    <div className={classes.root}>
      <Typography
        display="inline"
        gutterBottom
        variant="h3"
      >
            Manage Devices
      </Typography>
      <Divider />

      <DeviceInformationTable
        editOrCreateDevice = {editOrCreateUser}
        initialAuthLevelFilter = {initialAuthFilter}
      />
      <DeviceDetailsDialog 
        allOrgs = {allOrgs}
        device={deviceToUpdate}
        isOpen={parentCreateDialogIsOpen}
        handleClose={handleDeviceDetailsClose}
      />
    </div>
  );
};
