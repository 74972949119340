import React, {useEffect, useState} from 'react';
import { getAllCpapEventsForDevice } from '../common/apiGetUtilities';
import { useUserState } from './UserContext';
import { UserProps, CpapEventProps, convertUserDataToUserPropType, emptyUserState } from '../common/entityUtilities';
type Props = {
  children:React.ReactNode;
};
enum CpapEventListTypes {
  LOAD = 'LOAD',
  RESET = 'RESET',
  ADD_EVENT = 'ADD_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT'
}
type CpapEventListProps = {
  deviceId: string|null;
  allCpapEvents:CpapEventProps[],
  setDeviceId: object|undefined
}
interface CpapEventListAction extends CpapEventListProps{
  type:CpapEventListTypes;
}

const initialEventList:CpapEventListProps = {
  allCpapEvents: [],
  deviceId: null,
  setDeviceId: undefined
};



function cpapEventListReducer(state:CpapEventListProps, action:CpapEventListAction){
  switch(action.type) {
    case CpapEventListTypes.LOAD:{
      const {type, ...noType} = action;
      return noType;
    }
    case CpapEventListTypes.RESET:
      return { ...state, ...initialEventList};
    case CpapEventListTypes.ADD_EVENT:{
      const list = state.allCpapEvents;
      console.log('Old events:', list);
      const newEvents = action.allCpapEvents;
      console.log('new events:', newEvents);


      const newList = list.map( (item)=> {
        return item;
      });

      const oldSet = new Set(list.map(item=>item._id));

      for(const event of newEvents){
        if (! oldSet.has(event._id)){
          console.log('Adding cpap event', event._id);
          console.log('Device id:', state.deviceId);
          list.push(event);
          newList.push(event);
          // this is, unfortunately, a hack
          // if you return 'list' cpapinformationtable does NOT update with the newly added event

        }
      
      }      
      const listOfSubjectProps:CpapEventListProps = {
        allCpapEvents:newList,
        deviceId:state.deviceId,
        setDeviceId:state.setDeviceId
      };
      console.log('Returning:', listOfSubjectProps);
      return {  ...listOfSubjectProps};
    }
    case CpapEventListTypes.UPDATE_EVENT:{
      const origList = state.allCpapEvents;
      const updateEventList = action.allCpapEvents;
    
      console.log('origlist of events:', origList);
      console.log('update:', updateEventList);
      const eventValueDict = new Map();
      
      for (const event of updateEventList) {
        const {_id, ...others} = event;
        eventValueDict.set(_id, others);
      }
    
      const newEventSet = new Set(updateEventList.map(item=>item._id));
      const newList = origList.map((item) => {
        const eventId = item._id;
        if (newEventSet.has(eventId)) {
          const updateValues = eventValueDict.get(eventId);
          const updatedItem = {
            ...item,
            ...updateValues
          };   
          return updatedItem;
        }   
        return item;
      });  
      const listOfSubjectProps:CpapEventListProps = {
        allCpapEvents: newList,
        deviceId: state.deviceId,
        setDeviceId: undefined
      };
      console.log('Returning:', listOfSubjectProps);
      return {  ...listOfSubjectProps};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const CpapEventListStateContext = React.createContext<CpapEventListProps>(null!);
const CpapEventListDispatchContext = React.createContext<React.Dispatch<any>>(null!);

function CpapEventListProvider({ children }:Props) {
  const user = useUserState();
  
  const [deviceId, setDeviceId] = useState(null);
  const [state, dispatch] = React.useReducer(cpapEventListReducer, 
    initialEventList);
    
  useEffect(()=>{
    async function loadSubject(){
      console.log('[eventcontext] user:', user);
      if(user.authLevel <0 ) return;

      console.log('eventlistcontext, deviceid:', deviceId);
      const events = await getAllCpapEventsForDevice(user, deviceId);
      const initEvents:CpapEventProps[] = [];
      for(const rawUser of events){
        initEvents.push(rawUser);
      }
      dispatch({ type: CpapEventListTypes.LOAD, deviceId: deviceId,  allCpapEvents: initEvents, setDeviceId:setDeviceId});
    }
    loadSubject();
  }, [user, deviceId]);
  return (
    <CpapEventListStateContext.Provider value={{...state, deviceId}}>
      <CpapEventListDispatchContext.Provider value={dispatch}>
        {children}
      </CpapEventListDispatchContext.Provider>
    </CpapEventListStateContext.Provider>
  );
}

function useCpapEventListState() {
  const context = React.useContext(CpapEventListStateContext);
  if (context === undefined) {
    throw new Error('CpapEventListStateContext must be used within a CpapEventListProvider');
  }
  //console.log('[useCpapeventliststate] ', context);
  return context;
}

function useCpapEventListDispatch() {
  const context = React.useContext(CpapEventListDispatchContext);
  if (context === undefined) {
    throw new Error('useCpapEventListDispatch must be used within a CpapEventListProvider');
  }
  return context;
}

export { CpapEventListProvider, useCpapEventListState, useCpapEventListDispatch, CpapEventListTypes };
