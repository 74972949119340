//https://material.io/design/color/the-color-system.html#tools-for-picking-colors
import { orange, lightBlue, grey, red, green } from '@material-ui/core/colors';
import {createTheme} from '@material-ui/core/styles';

import 'typeface-montserrat';

const primary = '#ef834d'; //orange[800];
const secondary = lightBlue[600];
const warning = '#FFC260';
const success = '#3CD4A0';
const info = '#9013FE';

const darkTheme = {
  palette: {
    primary: {
      main: primary,
      contrastText: '#FFF'
    },
    secondary: {
      main: secondary,
      contrastText: '#FFF'
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
    },
    background: {
      default: '#424242',
      light: '#424242',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  sidebar: {
    color: orange[900],
    background: lightBlue[50],
    header: {
      color: '#000',
      background: lightBlue[700],
      brand: {
        color: '#000'
      }
    },
    footer: {
      color: orange[900],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: green[600]
    }
  }
};
const defaultTheme =  {
  palette: {
    primary: {
      main: primary,
      contrastText: '#FFF'
    },
    secondary: {
      main: secondary,
      contrastText: '#FFF'
    },
    success:{
      main: success,
      contrastText:'#FFF'
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
    },
    background: {
      default: '#424242',
      light: '#F3F5FF',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  header: {
    color: grey[200],
    background: orange[700],
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: '#000',
    background: '#FFF',
    header: {
      color: '#FFF',
      background: orange[700],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: lightBlue[900],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: green[600]
    }
  },
  body: {
    // background: '#F9F9FC'
    background:'#424242'
  },
  typography: {
    fontFamily:'Montserrat',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight:1.334
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight:1.6
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
    },
    button: {
      textTransform: 'none',
    }
  },
  
};

const customTheme = createTheme( {
  palette: {
    primary: {
      main: primary,
      contrastText: '#FFF'
    },
    secondary: {
      main: secondary,
      contrastText: '#FFF'
    },
    warning: {
      main: warning,
      contrastText: '#FFF'
    },
    success:{
      main: success,
      contrastText:'#FFF'
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      success: success,
      hint: '#B9B9B9',
    },
    background: {
      default: '#424242',
      light: '#F3F5FF',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  header: {
    color: grey[200],
    background: orange[700],
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: '#000',
    background: '#FFF',
    header: {
      color: '#FFF',
      background: orange[700],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: lightBlue[900],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: green[600]
    }
  },
  body: {
    // background: '#F9F9FC'
    background:'#424242'
  },
  typography: {
    fontFamily:'Montserrat',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight:1.334
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight:1.6
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
    },
    button: {
      textTransform: 'none',
    }
  }
});

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#ef834d',
    },
    secondary: {
      main: '#b8deed',
    },
    background: {
      default: '#b8deed',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
};

const muiDataTableTheme = 
    createTheme({
      overrides: {
        MuiTable: {
          root: {
            border: [[1, 'solid', lightBlue[700]]],
          },
        },
        MuiTableCell: {
          root: {
            borderColor: 'orange',
          },
          head: {
            background: lightBlue[700],
            '&:not(:last-child)': {
              borderRight: [[1, 'solid', '#c0c0c0']]
            },
          },
        },
        MuiTableSortLabel: {
          root: {
            alignItems: 'flex-start',
          },
        },
        MuiToolbar: {
          root: {
            color: lightBlue[700],
          },
        },
        MuiTableFooter: {
          root: {
            background: '#eee',
          },
        },
        // MUIDataTable
        MUIDataTableHeadCell: {
          sortLabelRoot: {
            // height: undefined,
          },
        },
      },
  });
export {darkTheme, defaultTheme, customTheme, muiDataTableTheme};