
import './App.css';
import React, {useState} from 'react';
import { HashRouter, Switch, Route, Redirect} from 'react-router-dom';
import * as AppRoutes from './common/routeNames';
import Dashboard from './pages/dashboard/Dashboard';
import Layout from './components/Layout/Layout';
import { useUserState } from './context/UserContext';
import Login from './pages/auth/login/Login';
import ResetPassword from './pages/auth/resetpassword/ResetPassword';

export function App() {

const {isAuthenticated, firebaseUser, authLevel} = useUserState();

function UserRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      // This will need to be stricter, ie check authlevel and
      // then give each page a required authlevel to view.
      render={props =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: `${AppRoutes.SIGN_IN}`,
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

const PublicRoute = ({ component, ...rest }) =>{
  const redirectPage = authLevel < 0?AppRoutes.USER_SETTINGS:AppRoutes.LANDING;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: `${redirectPage}`,
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

return (
    <HashRouter>
      <Switch>
        <Route exact 
          path={AppRoutes.LANDING}
          render={() => <Redirect to={AppRoutes.DASHBOARD}/>}
        />

        <Route
            exact
            path={AppRoutes.APP}
            render={() => <Redirect to={AppRoutes.DASHBOARD} />}
          />
        <UserRoute
            component={Layout}
            path={AppRoutes.APP}
        />
        <PublicRoute
            component={Login}
            path={AppRoutes.SIGN_IN}
        />
        <PublicRoute
          component={ResetPassword}
          path={AppRoutes.RESET_PASSWORD}
        />
        <Route component={Error} />

      </Switch>
    </HashRouter>
  );

}

export default App;
