import React, {useState, useEffect, useRef} from 'react';

// import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // Divider,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  // FormControl,
  Grid,
  FormGroup,
  FormControl,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {  KeyboardDatePicker } from '@material-ui/pickers';
import {
  DeviceLocation,
  emptySubjectState, SubjectHistoryProps, SubjectProps,
} from '../common/entityUtilities';

import {
  // useUserListState,
  useSubjectListDispatch,
  SubjectListTypes,
  useSubjectListState
} from '../context/SubjectListContext';

import { SubjectHistoryTable } from '../pages/subjecthistorytable/components/SubjectHistoryTable';
import { useUserDispatch, useUserState } from '../context/UserContext';
import { VentilationMode, Apparatus } from '../common/entityUtilities';

import {
  setSubjectInfo
} from '../common/apiSetUtilities';


import { 
  SubjectSchemaProps
} from '../common/mongoSchemas';
import { getSubjectHistoryFromSubjectId } from '../common/apiGetUtilities';
import { SubjectHistorySnapshotDialog } from './SubjectHistorySnapshotDialog';

type UIPropsForNewSubjectDialog = {
  birthWeight: number,
  currentWeight: number,
  patientIdentifier: string,
  dob:Date|undefined,
  length?:number|undefined,
  headCirc:number|undefined,
  chestCirc:number|undefined,
  abdominalCirc:number|undefined,
  ventilationMode:string|undefined,
  dueDate:Date|undefined,//needs to be nullalbe in ui but null== Date(0) for db
  org: string|undefined,
  devices: string[]|undefined,
  deviceLocation: string,
  apparatus: string
};

const emptySubjectUIState:UIPropsForNewSubjectDialog = {
  birthWeight: 0,
  patientIdentifier: '',
  dob: new Date(),
  dueDate: new Date(),
  length: 0,
  headCirc: 0,
  chestCirc: 0,
  abdominalCirc: 0,
  ventilationMode: VentilationMode.Bubble,
  deviceLocation: DeviceLocation.CHAMBER,
  apparatus: '',
  org: '',
  devices: [],
  currentWeight: 0
};

export type SubjectInfoDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  subjectInformation:SubjectProps|null,
  allOrgs: any[]

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        width: 200,
      },
    },
  }),
);

export const SubjectInfoDialog = (props:SubjectInfoDialogProps):JSX.Element=> {
  const classes = useStyles();
  let subjectHistoryRef = useRef<any>();
  const [subjectHistory, setSubjectHistory] = useState<SubjectHistoryProps|undefined>();
  const {isOpen, dialogComplete, subjectInformation, allOrgs}  = props;
  
  const userState = useUserState();
  const subjectListDispatch = useSubjectListDispatch();
  const userDispatch = useUserDispatch();
  const subjectList = useSubjectListState();

  const [dialogValue, setDialogValue] = useState(emptySubjectUIState);
  const [displaySubjectInformation, setDisplaySubjectInformation] = useState(true);
  // this can probably be replaced by the emmaUserId
  const [isNewSubject, setIsNewSubject] = useState(true);
  const [dob, setDob] = useState<Date>(new Date());
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [org, setOrg] = useState('');
  const [ventilationMode, setVentilationMode] = useState("");

  const emailSet = new Set(subjectList.allSubjects.map((subjectInfo)=>subjectInfo.patientIdentifier));
  const [errors, setErrors] = useState<any>({});

  const allModes = [VentilationMode.HFJV, VentilationMode.HFOV, VentilationMode.Bubble];
  const allApparatus = [Apparatus.Canula, Apparatus.Mask, Apparatus.Prongs];

  const [expandAccordion, setExpanded] = useState(false);
  const [subjectSnapshotDialogIsOpen, setSubjectSnapshotDialogIsOpen] = useState(false);
  const [subjectSnapshotToDisplay, setSubjectSnapshotToDisplay] = useState<SubjectProps|null>(null);


  // useEffect(()=>{
  //   console.log('error', errors);
  // },[errors]);

  useEffect(()=>{

    const fillUp = async ()=>{
      // The userinfo is of type UserProps
      if(subjectInformation===null){
        console.log('New subject initializing');
        setDisplaySubjectInformation(true);
        setIsNewSubject(true);
        setDialogValue(emptySubjectUIState);
        setSubjectHistory(undefined);
        return;      
      }

      setDisplaySubjectInformation(false);
      setIsNewSubject(false);

      console.log('userInformation in [useEffect]:', subjectInformation);
      let dialogValues:UIPropsForNewSubjectDialog =  {...emptySubjectUIState, 
        patientIdentifier: subjectInformation.patientIdentifier,
        abdominalCirc: subjectInformation.abdominalCirc,
        birthWeight: subjectInformation.birthWeight,
        currentWeight: subjectInformation.currentWeight,
        chestCirc: subjectInformation.chestCirc,
        dob: subjectInformation.dob, dueDate: subjectInformation.dueDate,
        length: subjectInformation.length,
        ventilationMode: subjectInformation.ventilationMode,
        devices: subjectInformation.devices,
        org: subjectInformation.org,
        apparatus: subjectInformation.apparatus
      };
      console.log('Setting dialogValues:', dialogValues);
      setDialogValue(dialogValues);
      if (subjectInformation?.ventilationMode){
        setVentilationMode(subjectInformation.ventilationMode);
      }
      if (subjectInformation?.org){
        setOrg(subjectInformation?.org);
      }

      console.log('Ventilation Mode:', subjectInformation.ventilationMode );
      console.log('Apparatus:', dialogValues.apparatus);
      console.log('Org:', subjectInformation?.org);
      const orgName = allOrgs.find(item=>item._id==dialogValues.org)?.name;
      console.log('Org name:', orgName);

      const subHistory = await getSubjectHistoryFromSubjectId(userState, subjectInformation._id);
      console.log('Subject history:', subHistory);
      setSubjectHistory(subHistory);

    }

    console.log('subjecttoupdate:', subjectInformation);
    fillUp();
    
   
  },[subjectInformation]);
  


  const handleAccordion = ()=> {
    setExpanded(!expandAccordion);
  };
  const updateUIWithValues = (createNewSubject: boolean, subjectId: string | undefined, 
    userPropertiesMongoDb: Partial<SubjectSchemaProps>) =>{

    let newPropsForSubject = userPropertiesMongoDb;
    // hope and pray the createNewUser is accurate. The userProperties are only what got update
    // maybe it should include the id of what was update.
    if(createNewSubject){
      const newSubject = {...emptySubjectState, ...newPropsForSubject };
      subjectListDispatch({type:SubjectListTypes.ADD_SUBJECT, allSubjects:[newSubject]});
    }else{
      // updating a subject that exists
      console.log('Updating with', newPropsForSubject);
      
    // Hack/bad design: need to attach the local email for the update until next pass
    subjectListDispatch({type:SubjectListTypes.UPDATE_SUBJECT, allSubjects:[newPropsForSubject]});
    
    }
  };
  


  const handleClose = () => {
    // new way of reseting dialog state when closing.
    setErrors({});
    setDialogValue(emptySubjectUIState);
    dialogComplete();
  };


  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('[handleSubmit] dialogValue:', dialogValue);
    console.log('[handleSubmit] userInformation:', subjectInformation);



    // Copied from UserSettings component
    const mongoUserValuesToUpdate:Partial<SubjectSchemaProps> = {
      patientIdentifier: dialogValue.patientIdentifier,
      dob: dialogValue.dob,
      dueDate:dialogValue.dueDate,
      length:dialogValue.length,
      headCirc:dialogValue.headCirc,
      chestCirc:dialogValue.chestCirc,
      abdominalCirc:dialogValue.abdominalCirc,
      birthWeight:dialogValue.birthWeight,
      currentWeight: dialogValue.currentWeight,
      ventilationMode:dialogValue.ventilationMode,
      devices: dialogValue?.devices,
      org: dialogValue.org,
      apparatus: dialogValue.apparatus
    };//

    console.log("Subject values to update:", mongoUserValuesToUpdate);
    
    
  

    // The user that is saving things
    const firebaseUser = userState.firebaseUser;
    // hmm wont this naturally be undefined?
    let userToUpdateId = subjectInformation?._id;
    if(isNewSubject) userToUpdateId = undefined;

    setSubjectInfo(firebaseUser, userToUpdateId, mongoUserValuesToUpdate)
      .then(storedUserData=>{ // Stored data is CpapUserSchemaProps
   
      
         
        updateUIWithValues(isNewSubject, userToUpdateId, storedUserData);
        
      });
    handleClose();
  };


  const displaySubjectSnapshot = (userInfo:SubjectProps|null) =>{
    //Order is important, first make visible then update user

    setSubjectSnapshotDialogIsOpen(true);
    console.log('The subjectinfo', userInfo);

    setSubjectSnapshotToDisplay(userInfo);
  };

  const snapshotDialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setSubjectSnapshotToDisplay(null);//reset the ui
    setSubjectSnapshotDialogIsOpen((prev)=> !prev);
  };

  return (

    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">          
          {subjectInformation?'Update subject':'Create subject'}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <div>
            <Typography
              color="secondary"
              variant="h6"
            >
                Subject Info
            </Typography>
            <TextField
              autoFocus
              error = {errors.email}
              fullWidth
              helperText = {errors.email}
              id="patientidentifier"
              // inputProps={{
              //   readOnly: !isNewSubject
              // }}
              label="Patient Identifier"
              margin="dense"
              onChange={(event) => 
              {
                const emailVal = event.target.value;
                if(emailSet.has(emailVal)){
                  setErrors({email:'ID is already registered.'});
                }else{
                  const {email, ...rest} = errors;
                  setErrors(rest);
                }
                setDialogValue({ ...dialogValue, patientIdentifier: emailVal });
              }}
              style={{ width: '80%' }}
              type="text"
              value={dialogValue.patientIdentifier}
            />

            <TextField
            autoFocus                
            id="birthweight"
            label="Birth Weight (g)"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, birthWeight: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.birthWeight}
            />

            <TextField
            autoFocus                
            id="birthweight"
            label="Current Weight (g)"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, currentWeight: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.currentWeight}
            />


            <TextField
            autoFocus                
            id="length"
            label="Length (cm)"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, length: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.length}
            />


            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Birth Date"
              margin="normal"
              onChange={(date: Date|null)=>{date && setDialogValue({...dialogValue, dob:date})}}
              value={dialogValue.dob}
            />
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              format="MM/dd/yyyy"
              label="Due Date"
              margin="normal"
              onChange={(date: Date|null)=>{date && setDialogValue({...dialogValue, dueDate:date})}}
              value={dialogValue.dueDate}
            />
            


            
            {/* <TextField
            autoFocus                
            id="headCirc"
            label="Head Circ"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, headCirc: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.headCirc}
            />
            <TextField
            autoFocus                
            id="chestCirc"
            label="Chest Circ"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, chestCirc: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.chestCirc}
            />
            <TextField
            autoFocus                
            id="abdominalCirc"
            label="Abdominal Circ"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, abdominalCirc: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.abdominalCirc}
            /> */}

          
            <FormControl fullWidth>
            <InputLabel shrink={true} 
                 >Select Org</InputLabel>
            <Select 
                id="org"
                label="Organization"
                value = {allOrgs.find(item=>item._id==org)?._id}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, org: event.target.value});
                  setOrg(event.target.value);
                  console.log('Current org:', org);
                  console.log('Setting Org:', event.target.value);
                  }
                }
              >
                 {allOrgs && allOrgs.map((option) => (
                  <MenuItem
                    key={option._id}
                    value={option._id}
                  >
                    {option.name} @ {option.location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
            <InputLabel shrink={true} 
                 >Select Apparatus</InputLabel>
            <Select 
                id="apparatus"
                label="Apparatus"
                value = {allApparatus.find(item=>item==dialogValue.apparatus)}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, apparatus: event.target.value});
                  }
                }
              >
                 {allApparatus && allApparatus.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option} 
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
            <FormControl fullWidth>
            <InputLabel >Select Mode</InputLabel>
            <Select 
                id="ventilationMode"
                label="Ventilation Mode"
                value={dialogValue.ventilationMode}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, ventilationMode: event.target.value});
                  setVentilationMode(event.target.value);
                  console.log('Current mode:', ventilationMode);
                  console.log('Setting ventilationMode:', event.target.value);
                  }
                }
              >
                {allModes && allModes.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>         

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant='outlined'
            onClick={handleClose}
          >
              Cancel
          </Button>
          <Button
            color="primary"
            variant='outlined'
            disabled={Object.keys(errors).length > 0 || !dialogValue.patientIdentifier}
            type="submit"
          >
            {subjectInformation? 'Update':'Create'}              
          </Button>
        </DialogActions>
      </form>

      {subjectHistory && 
      <div>
      <Accordion expanded={expandAccordion}>
            <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon color="primary"/>}
                id="subject-history-header"
                IconButtonProps={{
                  onClick:handleAccordion
                }}
            >
                <Typography
                    color="secondary"
                    variant="h6"
                >
                    History
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                <SubjectHistoryTable
                    allHistory={subjectHistory}
                    displaySubjectSnapshot = {displaySubjectSnapshot}
                    />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Typography variant="caption">
                        The history of updates to this subject.
                    </Typography>
                </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        <SubjectHistorySnapshotDialog 
          isOpen={subjectSnapshotDialogIsOpen} 
          dialogComplete={snapshotDialogComplete} 
          subjectInformation={subjectSnapshotToDisplay} 
          allOrgs={allOrgs}/>

        </div>
      }


    </Dialog>
    
  );
};
