

export  async function ReadJsonFromUrl(url: string) {

    await fetch(url)
      .then(res => res.json())
      .then( (out) => {
        console.log('Json output:', out);
        return out;
      })
      .catch(err => {throw err;});
      
}

  

export type SignInError = {
  code: string,
  message: string
}


export type FirebaseUserProps = {
  // From the authentication service (firebase)
  displayName: string;
  email: string;
  uid: string;
  photoURL: string;
  bearerToken: string;
  firebaseUserObject: any;
}

export type UserProps = {

  // firebaseUser will be undefined if the user is not
  // the one that is current logged in.
  firebaseUser?:FirebaseUserProps;
  // UI related property, can probably get factored out.
  isAuthenticated: boolean;
  phoneNumber:string;
  authenticationInProcess: boolean;
  // Properties that match database names
  // I would expect these to be replaced by EmmaUserSchemaProps
  // for consistancy
  userId?: string; // firebase id?
  createdAt?: Date; // hmm should this be undefined?
  firstname: string;
  lastname: string;
  authLevel: number;
  localDisplayName?: string;
  localEmail?:string;
  org?:string;
  _id?: string
  
}

export type SubjectProps = {

  birthWeight: number;
  currentWeight: number;
  headCirc?: number;
  chestCirc?: number;
  abdominalCirc?: number;
  length?: number;
  dob?: Date;
  dueDate?: Date;
  patientIdentifier: string;
  ventilationMode?: string;
  apparatus: any;
  org: string;
  devices: string[];
  _id?: string;
  createdAt?: Date;
  
}

export type SubjectHistoryProps = {
  _id?: string;
  deviceId: string;
  description: string;
  orgId: string;
  deviceLocation: string;
  subjectId: string;
}

export type DeviceHistoryProps = {
  _id?: string;
  subjectId: string;
  deviceLocation: string;
  deviceId: string;
  description: string;
}


export  type CpapEventProps = {

  _id?: string;
  flow: number;
  deviceId: string;
  subjectId: string;
  cpapLevel: number;
  deviceStatus: boolean;
  oxygenPercent: number;
  oxygenSaturation: number;
  apneaBradyCardia: boolean;
  comments: string;
  apparatus: any;
  position: string;
  chest_wiggle: string;
  updatedAt: Date;
  
}



export  type WiggleEventProps = {
  _id?: string;
  amplitude: number;
  deviceId: string;
  subjectId: string;
  frequency: number;
  map: number;
  fio2: number;
  spo2: number;
  pco2: number;
  tco2: number;

  itime: number;
  rate: number;
  pip: number;
  peep: number;
  comments: string;
  ventilation: string;
  position: string;
  chest_wiggle: string;
  updatedAt: Date;
}

export enum VentilationMode  {
  HFOV =  "High Frequency Oscillatory Ventilation",
  HFJV = "High Frequency Jet Ventilation",
  Bubble = "Bubble CPAP"
}


export enum Apparatus {
  Prongs = 'Prongs',
  Mask = 'Mask',
  Canula = 'Canula'
}

export enum DeviceLocation {
  SUBJECT = 'Subject',
  CHAMBER = 'Chamber'
}

export function convertUserDataToUserPropType(rawUserData:any):Partial<UserProps>{
  if(!rawUserData) return {};
  // Minor house keeping, not sure why any more though
  let userData = rawUserData;
  // Really don't know why this is here or when this would even happen. dlp
  if(typeof rawUserData === 'string') {
    // userData =JSON.parse(rawUserData);
    userData = JSON.parse(rawUserData); 
  }
  // In case of empty data, don't panic, just return empty
  if(Object.keys(userData).length === 0) return {...emptyUserState};

  //Clear out the props (or possible props) from the db that don't have a direct match
  // Since the type props and properties returned from the db match
  // not much is needed. but some work does need to be done to get them to match
  const {additionalProp1, email, subject, studyid, phoneNumber, ...matchingData} = userData;
  // console.log('Arge', email, 'split', matchingData);
  const userProp:Partial<UserProps> = {...matchingData };
  userProp.phoneNumber = phoneNumber;
  if(email){
    userProp.localEmail = email;
  }
  
  return userProp;
}

 

export const emptyFirebaseUserState:FirebaseUserProps = {
  displayName:'',
  email:'',
  uid:'',
  photoURL:'',
  bearerToken:'',
  firebaseUserObject:null
};

export const emptyUserState:UserProps = {
  firebaseUser:emptyFirebaseUserState,
  firstname:'', 
  lastname:'', 
  org:undefined,
  userId:undefined,
  authLevel:-1, //-1== unregistered, 0== normal user, 10===admin
  phoneNumber:'',
  createdAt:undefined,
  isAuthenticated:false,
  authenticationInProcess:false,
  _id: undefined
};

export const emptySubjectState:SubjectProps = {
  birthWeight: 0,
  dob: undefined,
  dueDate: undefined,
  patientIdentifier: "",
  apparatus: undefined,
  org: "",
  devices: [],
  currentWeight: 0,
  createdAt: undefined
};


export const emptyCpapEventState: CpapEventProps = {
  flow: 0,
  deviceId: "",
  subjectId: "",
  cpapLevel: 0,
  deviceStatus: false,
  oxygenPercent: 0,
  oxygenSaturation: 0,
  apneaBradyCardia: false,
  comments: "",
  apparatus: undefined,
  position: 'supine',
  chest_wiggle: 'not recorded',
  updatedAt: new Date()
};

export const emptyWiggleEventState: WiggleEventProps = {
  amplitude: 0,
  deviceId: "",
  subjectId: "",
  frequency: 0,
  map: 0,
  fio2: 0,
  spo2: 0,
  pco2: 0,
  tco2: 0,
  itime: 0,
  rate: 0,
  pip: 0,
  peep: 0,
  comments: "",
  ventilation: "",
  position: 'supine',
  updatedAt: new Date(),
  chest_wiggle: 'not recorded'
}

export function createFirebaseUser(firebaseUser:any, bearerToken=''):FirebaseUserProps{
  // TS cares, but it doesn't care THAT much about types
  // so ugly dlp, why are you doing this???
  const initialUser:FirebaseUserProps = {...emptyFirebaseUserState};
  initialUser.photoURL = firebaseUser.photoURL;
  initialUser.uid = firebaseUser.uid;
  initialUser.email = firebaseUser.email;
  initialUser.displayName = firebaseUser.displayName;
  initialUser.bearerToken = bearerToken;
  initialUser.firebaseUserObject = firebaseUser;
  // // There is also a refreshToken...
  return initialUser;
}



export type DeviceProps = {
  _id?: string;
  deviceId: string; // device name
  category: string;
  description: string;
  patientIdentifier: string;
  orgId: string;
  updatedAt: string;
  status: number; // 0 for off and 1 for on
  statusUpdatedAt: string;
  notes: string;
  subjectId: string;
  deviceLocation: string;
  createdAt: Date;

}
export const emptyDeviceState: DeviceProps = {
  _id: undefined,
  deviceId: '',
  patientIdentifier:'',
  category: '',
  description: '',
  orgId: '',
  updatedAt: '',
  status: 0,
  statusUpdatedAt: '',
  notes: '',
  subjectId: "",
  deviceLocation: "Chamber", // 'Subject' is the other option,
  createdAt: new Date()
};

export interface CpapEventForTable extends CpapEventProps  {
   patientIdentifier: string;
   org: string;
};

export interface WiggleEventForTable extends WiggleEventProps  {
  patientIdentifier: string;
  org: string;
};