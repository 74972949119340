import React, {useEffect, useState} from 'react';
import { Button , FormControlLabel, TextField} from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import { useSubjectListState } from '../../../context/SubjectListContext';
import { SubjectProps } from '../../../common/entityUtilities';
import { useSubjectState } from '../../../context/UserContext';
import { useUserState } from '../../../context/UserContext';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { muiDataTableTheme } from '../../../themes/default';
export function SubjectInformationTable(props) {


  const initialColumns = [
    {
      name:'patientIdentifier',
      label:'Patient ID',
      options: { }
    },
    {
      name:'dob',
      label:'DOB',
      options: { 
        customBodyRender: (value, tableMeta, updateValue) => (
          // <FormControlLabel
          //   label=""
          //   value={value}
          //   control={<TextField value={new Date(value).toLocaleDateString()} />}
          // />
          new Date(value).toLocaleDateString()
        )}
    },  
    {
      name:'ventilationMode',
      label:'Ventilation Mode',
      options:{}
    },
    {
      name:'authLevel',
      label:'Status',
      options:{
        display:false,
        customFilterListOptions: { render: v => `Status: ${v}` },
      }

    },
  ];
  const {editOrCreateUser, initialAuthLevelFilter} = props;
  const activeUser = useUserState();
  const { allSubjects } = useSubjectListState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Row click', rowMeta);
    editOrCreateUser(tableData[rowMeta.dataIndex]); //rowIndex is for visible index
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    customToolbar: () => {
      return (
        <Button 
          onClick={()=> {editOrCreateUser(null);}}
          color="primary"
          variant="outlined"
        >
          Add
        </Button>
        // <CustomToolbar />
      );
    }
  };

  useEffect(() =>{
    console.log('Setting up all subjects:', allSubjects);
    let toDisplay = allSubjects;
    const currentAuthLevel = activeUser.authLevel;
    if(currentAuthLevel <=0){
      // hopefully this is never undefined
      toDisplay = null;
    }
    console.log('Setting table to display:', toDisplay);
    setTableData(toDisplay);
  }, [allSubjects]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'patientIdentifier',
            label:'Patient ID',
            options: { }
          },
          {
            name:'dob',
            label:'Date of Birth',
            options: {
              customBodyRender: (value, tableMeta, updateValue) => (
                <FormControlLabel
                  label=""
                  value={new Date(value).toLocaleDateString}
                  control={<TextField value={new Date(value).toLocaleDateString} />}
                  onChange={event => updateValue(event.target.value)}
                />
              )
             }
          },    
          {
            name:'ventilationMode',
            label:'Ventilation Mode',
            options:{}
          },  
          {
            name:'authLevel',
            label:'Status',
            options:{
              display:false,
              filterList:[initialAuthLevelFilter],
              customFilterListOptions: { render: v => `Status: ${v}` },
            }
          },
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
      <MUIDataTable
        columns={columns}
        data={tableData}
        options={options}
        title={'Subjects'}
      />
    </ThemeProvider>
  );
}