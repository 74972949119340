
export interface CpapUserSchemaProps {
    userId?:string,//the mongoId
    email:string,
    uid?:string,//the firebase id, needed in order to create on mongo
    authLevel:number,
    firstname:string,
    lastname:string,
    org?:string,
    created:Date,

  }

  
  export interface SubjectSchemaProps {
    subjectId?:string,//the mongoId
    birthWeight:number,
    currentWeight: number,
    patientIdentifier:string,
    dob:Date|null,
    dueDate: Date|null,
    length:number,
    headCirc: number,
    chestCirc:number,
    abdominalCirc:number,
    ventilationMode:string,
    apparatus: string,
    org: string,
    devices: string[];

  }

  export interface DeviceSchemaProps {
    subjectId: string,
    org: string,
    deviceId: string,
    description: string,
    deviceLocation: string
  }


export  interface CpapEventSchemaProps  {
  _id?: string;
  flow: number;
  deviceId: string;
  subjectId: string;
  cpapLevel: number;
  deviceStatus: boolean;
  oxygenPercent: number;
  oxygenSaturation: number;
  apneaBradyCardia: boolean;
  comments: string;
  apparatus: number;
  position: string;
  chest_wiggle: string;
  updatedAt: Date;
}


export  interface WiggleEventSchemaProps {
  _id?: string;
  amplitude: number;
  deviceId: string;
  subjectId: string;
  frequency: number;
  map: number;
  fio2: number;
  spo2: number;
  pco2: number;
  tco2: number;

  itime: number;
  rate: number;
  pip: number;
  peep: number;
  comments: string;
  ventilation: string;
  position: string;
  chest_wiggle: string;

}

export const emptyCpapEventMongoSchema: CpapEventSchemaProps = {
  flow: 0,
  deviceId: "",
  subjectId: "",
  cpapLevel: 0,
  deviceStatus: false,
  oxygenPercent: 0,
  oxygenSaturation: 0,
  apneaBradyCardia: false,
  comments: "",
  apparatus: 0,
  position: 'supine',
  chest_wiggle: 'not recorded',
  updatedAt: new Date()
}

export const emptyWiggleEventMongoSchema: WiggleEventSchemaProps = {
  amplitude: 0,
  deviceId: "",
  subjectId: "",
  frequency: 0,
  map: 0,
  fio2: 0,
  spo2: 0,
  pco2: 0,
  tco2: 0,
  itime: 0,
  rate: 0,
  pip: 0,
  peep: 0,
  comments: "",
  ventilation: "",
  position:'supine',
  chest_wiggle: 'not recorded'
}

  export const emptySubjectMongoSchema: SubjectSchemaProps = {
    birthWeight: 0,
    patientIdentifier: "",
    dob: new Date(),
    dueDate: new Date(),
    length: 0,
    headCirc: 0,
    chestCirc: 0,
    abdominalCirc: 0,
    ventilationMode: "",
    apparatus: "",
    org: "",
    devices: [],
    currentWeight: 0
  };

  export const emptyDeviceMongoSchema: DeviceSchemaProps = {
    subjectId: "",
    org: "",
    deviceId: "",
    description: "",
    deviceLocation: ""
  }
  
  export const emptyUserMongoSchema:CpapUserSchemaProps = {
    email:'',
    authLevel:	-1,
    firstname:	'',
    lastname:	'',
    created:new Date(),
    uid:'unauthenticatedId'
  };