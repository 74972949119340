import React from 'react';
import socketIOClient from 'socket.io-client';
const SocketIOContext = React.createContext(null);

export const withSocketIO = Component => props => (
  <SocketIOContext.Consumer>
    {socketio => 
      <Component 
        {...props}
        socketio={socketio}
      />
    }
  </SocketIOContext.Consumer>
);

let servicesSocketInitiated = false;
let socket = null;
let socketEndpoint = process.env.REACT_APP_CPAP_API;

if (!socketEndpoint) {
  console.log('Getting local socket endpoint');
  socketEndpoint = 'http://127.0.0.1:4001';
}
export function initializeSocket() {
  if (!servicesSocketInitiated){
    socket = socketIOClient(socketEndpoint);
    servicesSocketInitiated = true;
    return socket;
  }
  else{
    return socket;
  }
}
export default SocketIOContext;