import React, {useState} from 'react';

import classNames from 'classnames';
// styles
import useStyles from './styles';

import {
    AppBar, 
    IconButton,
    Toolbar,
    Menu,
    MenuItem,
} from '@mui/material';

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
  } from '../../context/LayoutContext';


  import {
    Menu as MenuIcon,
    Person as AccountIcon,
    ArrowBack as ArrowBackIcon,
    PersonOutlineOutlined as PersonIcon,
    DuoRounded,
    DuoOutlined,
    ExitToApp as LogoutIcon,
    DeleteSweepOutlined as DeleteIcon,
    VideoCall
  } from '@material-ui/icons';
// components
import { 
    Typography, 
    Button 
  } from '../Wrappers/Wrappers';

import {User, LogOut} from 'react-feather';
import { useUserDispatch, useUserState, signOut, refreshToken } from '../../context/UserContext';


export default function Header(props) {

    const [profileMenu, setProfileMenu] = useState(null);
    const [userName, setUserName] = useState(null);
  // Global
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();

  const userDispatch = useUserDispatch();
  const userState = useUserState();

  const classes = useStyles();

return (
    <AppBar 
        className={classes.appBar}
        position="fixed"
    >
        <Toolbar className={classes.toolbar}>
        <IconButton
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
            
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>          
        <img
          alt="Logo"
          className={classes.logotype}
          src="/images/logos/logo-no-background-36.png"
        />
        <div className={classes.grow} />


        <IconButton
          aria-controls="profile-menu"
          aria-haspopup="true"
          className={classes.headerMenuButton}
          color="inherit"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <User classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          anchorEl={profileMenu}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
          id="profile-menu"
          onClose={() => setProfileMenu(null)}
          open={Boolean(profileMenu)}
        >
          <div className={classes.profileMenuUser}>
            <Typography
              align="center"
              variant="h5"
              weight="medium"
            >
              {userState.localDisplayName}
            </Typography>
          </div>
          <MenuItem>
            <IconButton
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => signOut(userDispatch, props.history)}
            >
                <LogoutIcon/>
                <Typography variant='h6' weight="medium">
                Sign Out
                </Typography>     
            </IconButton>
          </MenuItem>
          

        </Menu>
        </Toolbar>
    </AppBar>
);
}