import axios from 'axios';
import https from 'https';
import { getCpapApiEndpoint, setAxiosDefaultHeaders } from './apiBaseUtilities';
import { CpapUserSchemaProps, emptyUserMongoSchema } from './mongoSchemas';

import {
    UserProps,
    FirebaseUserProps,
    SubjectProps,
    SubjectHistoryProps,
    DeviceHistoryProps
} from './entityUtilities';

const cpapApiEndpoint = getCpapApiEndpoint();


export async function getCpapUserWithFilter(firebaseUser:FirebaseUserProps|undefined, email:any)
:Promise<CpapUserSchemaProps|undefined>{
  if(!cpapApiEndpoint || !firebaseUser){
    return emptyUserMongoSchema;
  }

  const {uid, bearerToken } = firebaseUser;
  const apiendpoint = `${cpapApiEndpoint}users/email/${email}`;
  setAxiosDefaultHeaders(uid, bearerToken);
  try{
  console.log('api endpt: ', apiendpoint);
//   console.log('uid:', uid);
//   console.log('token:', bearerToken);
    const responseData = await axios.get(apiendpoint);
    console.log('response:', responseData);
    if(!responseData.data) return undefined;
    if(responseData.data.length === 0) return undefined;
    return responseData.data[0];
  }catch(error){
    console.log('401 ERROR on /users');
    return undefined;
  }
}

export async function checkIfEmailIsTaken(firebaseUser:FirebaseUserProps|undefined, email:String) : Promise<boolean>{

    if(!cpapApiEndpoint || !firebaseUser){
      return false;
    }
  
    console.log('Checking if email is taken:', email);
  
    let emailLowerCase = email.toLowerCase();
    const {uid, bearerToken } = firebaseUser;
    const filter = {'where':{'email':emailLowerCase } };
  
    const apiendpoint = `${cpapApiEndpoint}users/email/${emailLowerCase}`;
    setAxiosDefaultHeaders(uid, bearerToken);
    // try{
    const responseData = await axios.get(apiendpoint);
  
    console.log('Is email taken:', responseData);
    if(!responseData.data) return false;
    if(responseData.data.length === 0) return false;
  
    //this email is taken, meaning there's some emmauser entry that has this email
    //
    //what if the user has retyped their own email back?
    if (firebaseUser.uid == responseData.data[0].uid){
      console.log('firebaseuser uid:', firebaseUser.uid);
      console.log('found cpapuser:', responseData.data[0]);
      return false;
    }
  
    return true;
  
  }

// Used to set up the user that is logged in, so don't the subject is needed.
export async function getUserDataWithEmail(email:string, bearerToken:string, uid:string){
    if(!cpapApiEndpoint){
      return null;
    }
    const firebaseUser:FirebaseUserProps = {
      uid:uid,
      bearerToken:bearerToken,
      email:email,
      photoURL:'',
      displayName:email,
      firebaseUserObject:null
    };
    return getCpapUserWithFilter(firebaseUser, email);
  }
  
  // when users register with PHONE auth, firebaseUser object might not have email
  // but the user might still be in the database under emmauser collection
  export async function getUserDataWithUid (uid: string, bearerToken: string) {
  
    if(!cpapApiEndpoint){
      return null;
    }
    const filter = {'where':{'uid':uid } };
    const firebaseUser:FirebaseUserProps = {
      uid:uid,
      bearerToken:bearerToken,
      email:'',
      photoURL:'',
      displayName:'',
      firebaseUserObject:null
    };
    return getCpapUserWithFilter(firebaseUser, filter);
  }


export async function getAllCpapUsers(user:UserProps):Promise<any[]>{
    if(!cpapApiEndpoint){
      // might be good to have some data for this
      return [];
    }
  
    let rawUserData:any = [];
    if(!user.firebaseUser) return rawUserData;
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);

  
    const userUrl = `${cpapApiEndpoint}users`;
  
    const usersResponse = await axios.get(userUrl);
    rawUserData = usersResponse.data;

    return rawUserData;
  }
  

export async function getAllSubjects(user:UserProps):Promise<SubjectProps[]>{
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return [];
  }

  let rawUserData:any = [];
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);


  const userUrl = `${cpapApiEndpoint}subjects`;

  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;

  return rawUserData;
}


export async function getAllSubjectsForOrg(user:UserProps,id: string):Promise<SubjectProps[]>{
  console.log('[getAllSubjectsForOrg]');
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return [];
  }

  let rawUserData:any = [];
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);


  const userUrl = `${cpapApiEndpoint}subjects/org/${id}`;
  console.log('Get subjects for org at:', userUrl);
  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;
  console.log('API returned:', rawUserData);
  return rawUserData;
}

export async function getSubjectFromId(user:UserProps, id: string):Promise<SubjectProps|undefined>{
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return undefined;
  }

  let rawUserData:any = [];
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  console.log('getsubject with id:', id);
  const userUrl = `${cpapApiEndpoint}subjects/${id}`;

  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;

  return rawUserData;
}


export async function getSubjectHistoryFromSubjectId(user:UserProps, id: string|undefined):
  Promise<SubjectHistoryProps|undefined>{
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return undefined;
  }

  let rawUserData:any = [];
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  console.log('getsubjecthistory for subject id:', id);
  const userUrl = `${cpapApiEndpoint}subjecthistory/subject/${id}`;

  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;

  console.log('History:', rawUserData);
  return rawUserData;
}

export async function getDeviceHistory(user:UserProps, id: string|undefined):
  Promise<any|undefined>{
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return undefined;
  }

  let rawUserData:any = [];
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  console.log('getdevicehistory for device id:', id);
  const userUrl = `${cpapApiEndpoint}devicehistory/device/${id}`;

  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;
  console.log('Device History:', rawUserData);
  return rawUserData;
  }

export async function getSubject(user:UserProps, patientIdentifier: string):Promise<SubjectProps[]>{
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return [];
  }

  let rawUserData:any = [];
  if(!user.firebaseUser) return rawUserData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  console.log('getsubject with patientid:', patientIdentifier);
  const userUrl = `${cpapApiEndpoint}subjects/patient/${patientIdentifier}`;

  const usersResponse = await axios.get(userUrl);
  rawUserData = usersResponse.data;

  return rawUserData;
}

export async function getAllOrgs(user: UserProps): Promise<any[]>{

    if(!cpapApiEndpoint){
        // might be good to have some data for this
        return [];
    }
    let rawData: any = [];

    if(!user.firebaseUser) return rawData;
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);

    if (user?.authLevel == 10){
      const useUrl = `${cpapApiEndpoint}orgs`;  
      const response = await axios.get(useUrl);
      rawData = response.data;
      return rawData;
    }
    else { // non super admin user
    
    }
    return rawData;

}


export async function getAllDevices(user: UserProps): Promise<any[]>{

    if(!cpapApiEndpoint){
        // might be good to have some data for this
        return [];
    }
    let rawData: any = [];

    // if authLevel == 10, get ALL devices

    if(!user.firebaseUser) return rawData;
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);

    if (user.authLevel == 10){
      // super admin, return all devices
      const getUrl = `${cpapApiEndpoint}devices`;
      const response = await axios.get(getUrl);
      return response?.data;
    }
    // otherwise, just return devices that are associated with the user's org
    const userUrl = `${cpapApiEndpoint}devices/org/${user?.org}`;
    console.log('hitting up endpoint:', userUrl);
    console.log('org id:', user.org);
    const usersResponse = await axios.get(userUrl);
    rawData = usersResponse.data;
    console.log('responseData:', rawData);
    return rawData;

}



export async function getDeviceStatus(user: UserProps, deviceId: string): Promise<any[]>{

    if(!cpapApiEndpoint || deviceId==undefined || deviceId == ''){
        // might be good to have some data for this
        return [];
    }
    let rawData: any = [];


    if(!user.firebaseUser) return rawData;
    const {uid, bearerToken } = user.firebaseUser;
    setAxiosDefaultHeaders(uid, bearerToken);

  
    const userUrl = `${cpapApiEndpoint}devicestatus/device/${deviceId}`;
    const usersResponse = await axios.get(userUrl);
    rawData = usersResponse.data;
    return rawData;

}



export async function getAllCpapEvents(user: UserProps): Promise<any[]>{

  if(!cpapApiEndpoint){
      // might be good to have some data for this
      return [];
  }
  let rawData: any = [];


  if(!user.firebaseUser) return rawData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);


  const userUrl = `${cpapApiEndpoint}cpapevents`;
  const usersResponse = await axios.get(userUrl);
  rawData = usersResponse.data;
  return rawData;

}


export async function getAllCpapEventsForDevice(user: UserProps, deviceId: string|null): Promise<any[]>{

  console.log('get cpap events for device:', deviceId);
  if(!cpapApiEndpoint){
      // might be good to have some data for this
      return [];
  }
  let rawData: any = [];


  if(!user.firebaseUser) return rawData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);


  const userUrl = `${cpapApiEndpoint}cpapevents/device/${deviceId}`;
  const usersResponse = await axios.get(userUrl);
  rawData = usersResponse.data;
  return rawData;

}


export async function getAllWiggleEventsForDevice(user: UserProps, deviceId: string|null): Promise<any[]>{

  console.log('get cpap events for:', deviceId);
  if(!cpapApiEndpoint){
      // might be good to have some data for this
      return [];
  }
  let rawData: any = [];


  if(!user.firebaseUser) return rawData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);


  const userUrl = `${cpapApiEndpoint}wiggleevents/device/${deviceId}`;
  const usersResponse = await axios.get(userUrl);
  rawData = usersResponse.data;
  return rawData;

}
export async function getDeviceDetails(user: UserProps, deviceId: string): Promise<any>{
  
  if(!cpapApiEndpoint){
    // might be good to have some data for this
    return ;
  }

  if(!user.firebaseUser) return;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);

  let deviceData:any = {};
  
  // get device

  let useUrl = `${cpapApiEndpoint}devices/${deviceId}`;
  const usersResponse = await axios.get(useUrl);
  deviceData = {...deviceData, device: usersResponse.data};

  // get org details
  let orgId = deviceData['device']?.orgId;
  useUrl = `${cpapApiEndpoint}orgs/${orgId}`;
  const orgResponse = await axios.get(useUrl);
  if(orgResponse){
    deviceData = {...deviceData, org: orgResponse.data};
  }

  // get devicestatus

  useUrl = `${cpapApiEndpoint}devicestatus/device/${deviceId}`;
  const deviceStatus = await axios.get(useUrl);
  
  if (deviceStatus){
    deviceData = {...deviceData, status: deviceStatus.data?.status};
  }

  // get subject details
  let subjectId = deviceData['device']?.subjectId;
  if (subjectId){
    useUrl = `${cpapApiEndpoint}subjects/${subjectId}`;
    const subjectDetails = await axios.get(useUrl);
    if (subjectDetails){
      deviceData = {...deviceData, subject: subjectDetails.data};
    }
  }
  console.log('Device data from API:', deviceData);
  return deviceData;



}



export async function getDeviceData(user: UserProps, deviceId: string, startdate: string,
  enddate: string): Promise<any[]>{

  if(!cpapApiEndpoint){
      // might be good to have some data for this
      return [];
  }
  let rawData: any = [];


  if(!user.firebaseUser) return rawData;
  const {uid, bearerToken } = user.firebaseUser;
  setAxiosDefaultHeaders(uid, bearerToken);


  let params = {"startdate": startdate, "enddate": enddate};
  const userUrl = `${cpapApiEndpoint}events/recent/${deviceId}`;
  const usersResponse = await axios.post(userUrl, params);
  rawData = usersResponse.data;
  return rawData;

}
