import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
    background:'white'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: 'calc(100vw - 240px)',
    minHeight: '100vh',
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  footer:{
    position: 'relative',
    bottom:10,
    top:10,
    left:0,
    width:'100%',
    color:'gray'
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));