import { Accordion, AccordionSummary, AccordionDetails, Box, Dialog, 
  Divider,Grid,  Card, CardContent, Toolbar, Typography, AppBar, Paper, 
  Tooltip, Button, IconButton, TextField, DialogContent,
  FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { ArrowBack } from "@material-ui/icons";
import { 
  createStyles, makeStyles,
  // Theme 
} from '@material-ui/core/styles';
import { useEffect } from "react";
import { DeviceHistoryTable } from "../pages/devicehistorytable/components/DeviceHistoryTable";
import { getDeviceDetails, getAllSubjectsForOrg, getDeviceHistory} from "../common/apiGetUtilities";
import { sendDeviceCommand, setDeviceInfo } from "../common/apiSetUtilities";
import { useUserState , refreshToken} from "../context/UserContext";
import { useDeviceListDispatch, DeviceListTypes, useDeviceListState } from "../context/DeviceListContext";
import { CpapEventListProvider } from "../context/CpapEventListContext";
import { CpapEventInformationTable } from "../components/Events/CpapEventInformationTable";
import { WiggleEventInformationTable } from "../components/Events/WiggleEventInformationTable";
import { DeviceLocation, emptyCpapEventState, emptyWiggleEventState } from "../common/entityUtilities";
import { WiggleEventListProvider } from "../context/WiggleEventListContext";
import { CpapEventInfoDialog } from "./CpapEventInfoDialog";
import { WiggleEventInfoDialog } from "./WiggleEventInfoDialog";
import { DeviceHistorySnapshotDialog } from "./DeviceHistorySnapshotDialog";
import { DeviceHistoryProps } from "../common/entityUtilities";
const useStyles = makeStyles((theme) =>
  
  createStyles({
    root: { height:'50%'},
    appBar: {
      position: 'relative',
      color: 'secondary'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    saving: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    videoList: {
      minWidth: 340, 
    },
    card: {
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto',
      transition: '0.3s',
      boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
      '&:hover': {
        boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
      }
    },
    carddivider: {
      margin: `${theme.spacing.unit}px 0`
    },
    cardmedia: {
      paddingTop: '56.25%'
    },
    cardcontent: {
      textAlign: 'left',
      padding: theme.spacing.unit * 3
    },
    detailsTab: {
      maxWidth:'100%',
      minWidth:'0%',
      width:'auto',
      padding:'8px',
      flexGrow:1
    },
    detailsRoot:{
      flexGrow:1,
      width:'100%'
    },
    uploadTab:{
      maxWidth: '100%',
      minWidth: '0',
      width:'100%',
      margin: 'auto'
    },
    container:{
      display: 'flex'
    },
    midContainer:{
      marginTop:theme.spacing(1),
      flexGrow: 1,
      flexDirection:'column',
      display: 'flex',
      height:'60%'
    },
    
  }),
);
export const DeviceDetailsDialog = (props) => {


  const [deviceHistory, setDeviceHistory] = useState();
  const {isOpen, handleClose, device, allOrgs, deviceStatusMessage, handleStart, handleStop} = props;
  const user = useUserState();
  const deviceListDispatch = useDeviceListDispatch();
  const deviceList = useDeviceListState();
  const [isNewDevice, setIsNewDevice] = useState(true);
  const [dialogValue, setDialogValue] = useState({});
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState('Off');
  const [deviceId, setDeviceId] = useState('');
  const [friendlyDeviceId, setFriendlyDeviceId] = useState('');
  const [deviceDescription, setDeviceDescription] = useState('');
  const [cpapEventCreateDialogIsOpen, setCpapEventCreateDialogIsOpen] = useState(false);
  const [cpapEventToUpdate, setCpapEventToUpdate] = useState(emptyCpapEventState);
  const [wiggleEventToUpdate, setWiggleEventToUpdate] = useState(emptyWiggleEventState);
  const [wiggleEventCreateDialogIsOpen, setWiggleEventCreateDialogIsOpen] = useState(false);

  const initialAuthFilter = props?.history?.location?.state?.initialAuthFilter;
  const allLocations = [DeviceLocation.CHAMBER, DeviceLocation.SUBJECT];
  const [org, setOrg] = useState('');
  const [currentOrg, setCurrentOrg] = useState();
  const [currentSubject, setCurrentSubject] = useState({});
  const [allSubjects, setAllSubjects] = useState([]);
  const [subject, setSubject] = useState(null);
  const [currentDeviceLocation, setCurrentDeviceLocation] = useState('');

  const [expandAccordion, setExpanded] = useState(false);
  const [expandEventsAccordion, setEventsExpanded] = useState(false);
  const [expandMgmtAccordion, setExpandedMgmt] = useState(true);

  const [deviceSnapshotDialogIsOpen, setDeviceSnapshotDialogIsOpen] = useState(false);
  const [deviceSnapshotToDisplay, setDeviceSnapshotToDisplay] = useState(null);

  useEffect( ()=>{
    if (isOpen != null){
      console.log('isopen:', isOpen);
    }
    if (isOpen){
      setFriendlyDeviceId('');
      setDeviceDescription('');
      setDeviceId('');
      setCurrentOrg();
      setCurrentSubject(null);
    }
  },[isOpen]);


  useEffect( ()=>{
    const getDetails = async () => {
      if (!device) {
        console.log('Add New Device');
        setIsNewDevice(true);
        setDeviceHistory(undefined);
      }
      else{
        setIsNewDevice(false);
        console.log('Getting device details from API:', device);
        const details = await getDeviceDetails(user, device?._id);
        setDeviceDetails(details);
        const dHistory = await getDeviceHistory(user, device?._id);
        setDeviceHistory(dHistory);
        setOrg(details?.org?._id);
        console.log('deviceDetails:', details);
        console.log('Setting currentOrg:', details?.org);
        setCurrentOrg(details?.org);
        setCurrentSubject(details?.subject);
        setSubject(details?.subject?._id);
        console.log('Setting device id:', details?.device._id);
        setDeviceId(details?.device._id);
        setFriendlyDeviceId(details?.device?.deviceId);
        setDeviceDescription(details?.device?.description);
        setCurrentDeviceLocation(details?.device?.deviceLocation);
        
        if (details?.status==0){
          setDeviceStatus('Off');
        }
        else if (details?.status == 1){
          setDeviceStatus('On');
        }
      }
    };

    if(device?._id == undefined){
      console.log('device is undefined');
    }
    console.log('Getting details for device:', device);
    getDetails(device);
    console.log('[deviceId with details]:', deviceDetails?.device?._id);
    

  }, [device]);

  useEffect(()=>{
    const getSubjects = async ()=>{
      
      const orgId = org
      console.log('Current Org ID:', orgId);
      if (orgId){

        setCurrentSubject(null);
        setSubject(null);
        // get all subjects associated with this orgId
        console.log('getting subjects for org:', orgId);
        const response = await getAllSubjectsForOrg(user,orgId);
        console.log('subjects for org:', response);
        setAllSubjects(response);
      }
      
    };
    
    getSubjects();

  }, [deviceDetails, currentOrg, org]);

  const classes = useStyles();
  

  const handleAccordion = ()=> {
    setExpanded(!expandAccordion);
  };

  const handleMgmtAccordion = () => {
    setExpandedMgmt(!expandMgmtAccordion);
  }

  const handleEventsAccordion = () => {
    setEventsExpanded(!expandEventsAccordion);
  }

  const cpapEventDialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setCpapEventToUpdate(null);//reset the ui
    // setParentCreateDialogIsOpen(false);
    setCpapEventCreateDialogIsOpen((prev)=> !prev);
  };

  const wiggleEventDialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setWiggleEventToUpdate(null);//reset the ui
    // setParentCreateDialogIsOpen(false);
    setWiggleEventCreateDialogIsOpen((prev)=> !prev);
  };

  const displayDeviceSnapshot = (deviceInfo) =>{
    //Order is important, first make visible then update device

    setDeviceSnapshotDialogIsOpen(true);
    console.log('The deviceinfo', deviceInfo);

    setDeviceSnapshotToDisplay(deviceInfo);
  };
  const snapshotDialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setDeviceSnapshotToDisplay(null);//reset the ui
    setDeviceSnapshotDialogIsOpen((prev)=> !prev);
  };

  const editOrCreateCpapEvent = (eventInfo) =>{
    //Order is important, first make visible then update user

    setCpapEventCreateDialogIsOpen(true);
    console.log('The eventinfo', eventInfo);
    setCpapEventToUpdate(eventInfo);
  };

  const editOrCreateWiggleEvent = (eventInfo) =>{
    //Order is important, first make visible then update user
    setWiggleEventCreateDialogIsOpen(true);
    setWiggleEventToUpdate(eventInfo);
    
    console.log('The eventinfo', eventInfo);
    //setSubjectToUpdate(userInfo);
  };


  const handleSubmit = async (event) => {
    event?.preventDefault();

    await refreshToken(user); // so the page doesn't go stale

    console.log('[handleSubmit] dialogValue:', dialogValue);
    console.log('[handleSubmit] deviceInformation:', device);



    // Copied from UserSettings component
    const mongoDeviceValuesToUpdate = {
      deviceLocation:currentDeviceLocation,
      deviceId: friendlyDeviceId,
      description: deviceDescription,
      orgId: currentOrg ? currentOrg?._id: org,
      subjectId: currentSubject?._id
    };//

    console.log("Device values to update:", mongoDeviceValuesToUpdate);
    
    
  

    // The user that is saving things
    const firebaseUser = user.firebaseUser;
    let deviceToUpdateId = deviceId;

    if (isNewDevice){
      deviceToUpdateId = undefined;
    }
    setDeviceInfo(firebaseUser, deviceToUpdateId, mongoDeviceValuesToUpdate)
      .then(storedUserData=>{ // Stored data is DeviceSchemaProps
   
        console.log('SAVE finished:', storedUserData);
        updateUIWithValues(isNewDevice, deviceToUpdateId, storedUserData);
        
      });

    handleClose();
  };

  const updateUIWithValues = (createNewDevice, deviceId, deviceProperties) => {
    console.log('Updating device with:', deviceProperties);
    if (isNewDevice){
      const newDevice = deviceProperties;
      console.log('Dispatching new device added event');
      deviceListDispatch({type:DeviceListTypes.ADD_DEVICE, allDevices: [newDevice]});
    } 
    else{
      console.log('Dispatching update device event');
      deviceListDispatch({type:DeviceListTypes.UPDATE_DEVICE, allDevices: [deviceProperties]});
    }
  }

  const handleDeviceStart = async () => {
    console.log('Start device:', device);
    handleStart(); // call back to the calling function (Dashboard)
    await refreshToken(user);
    const result = await sendDeviceCommand(user?.firebaseUser, device?._id, "start");
    console.log('Result of command post:', result);
  }
  
  const handleDeviceStop = async () => {
    console.log('Stop device:', device);
    handleStop(); //callback to the calling function (Dashboard)
    await refreshToken(user);
    const result = await sendDeviceCommand(user?.firebaseUser, device?._id, "stop");
    console.log('Result of command post:', result);
  }

    return(


      <CpapEventListProvider value={{setDeviceId:setDeviceId}}>
        <WiggleEventListProvider value={{setDeviceId: setDeviceId}}>
          <Dialog
              fullScreen
              open={isOpen}
          >
              <AppBar className={classes.appBar} color="secondary">
                  <Toolbar>
                    <Tooltip title="Back to Dashboard">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      edge="start"
                      onClick={handleClose}
                      variant="outlined"
                    >
                      <ArrowBack                
                        fontSize="large"
                      />
                    </IconButton>
                    </Tooltip>

                    <Typography
                      className={classes.title}
                      style={{marginRight:20}}
                      variant="h6"
                    >
                      Device: {device && device?.deviceId}  
                    </Typography>

                    <Typography 
                      className={classes.title}
                      style={{marginRight:20}}
                      variant="h6">
                    Org: {deviceDetails?.org && deviceDetails?.org.name} @ 
                      {deviceDetails?.org && deviceDetails?.org.location}
                    </Typography>
                  </Toolbar>
              </AppBar>

          <Divider my={6} />
          <DialogContent>

          <Grid container spacing={3}>
          <Grid item>
          <Card> 
          <CardContent>    
            <form onSubmit={handleSubmit}>
            <Grid container alignItems="stretch" spacing={2}>
              <Grid item align="center" xs={12} sm={12} md={12}>
                <Typography variant='h5' color="secondary"> Kiwi Device Details</Typography>
              </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField    
                    InputLabelProps = {{readOnly: false, shrink:true}}
                    label="Device"
                    value = {friendlyDeviceId}
                    onChange={(event)=>{
                      setFriendlyDeviceId(event.target.value);
                    }}>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <TextField    
                    InputLabelProps = {{readOnly: false, shrink:true}}
                    label="Description"
                    fullWidth
                    multiline
                    value = {deviceDescription}
                    onChange={(event)=>{
                      setDeviceDescription(event.target.value);}}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField    
                    InputLabelProps = {{readOnly: true, shrink:true}}
                    label="Device ID"
                    value = {deviceId}>
                    
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    readOnly
                    InputLabelProps = {{readOnly: true, shrink:true}}
                    label="Org"
                    value = {allOrgs && allOrgs.find(item=>item._id==org)?.name}>
                    </TextField>
                </Grid>
                { allOrgs && <Grid item xs={12} sm={4} md={4}>
                  <FormControl>
                    <InputLabel shrink={true}
                        >Select Org</InputLabel>
                    <Select 
                        id="org"
                        label="Organization"
                        value = {allOrgs.find(item=>item._id==org)?._id}
                        // m={2}
                        notched={true}
                        onChange={(event) => {
                          setDialogValue({ ...dialogValue, org: event.target.value});
                          if (org !== event.target.value){
                            setOrg(event.target.value);
                          }
                          console.log('Current org:', org);
                          console.log('New Org Id:', event.target.value);
                          }
                        }
                      >
                        {allOrgs && allOrgs.map((option) => (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                          >
                            {option.name} @ {option.location}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                }

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    autoFocus
                    InputLabelProps = {{readOnly: true, shrink:true}}
                    label="Patient Id"
                    value = {currentSubject?.patientIdentifier}>
                    
                    </TextField>
                </Grid>
                {  <Grid item xs={12} sm={8} md={6}>
                  <FormControl>
                    <InputLabel shrink={true}
                        >Select Patient</InputLabel>
                    <Select 
                        id="subject"
                        label="Patient"
                        value = {allSubjects.find(item=>item._id==subject)?.patientIdentifier}
                        // m={2}
                        onChange={(event) => {
                          setDialogValue({ ...dialogValue, org: event.target.value});
                          setSubject(event.target.value);
                          setCurrentSubject(allSubjects && allSubjects.find(item=>item._id==event.target.value));
                          console.log('Current subject ID:', subject);
                          console.log('Setting Subject:', event.target.value);
                          }
                        }
                      >
                        {allSubjects && allSubjects.map((option) => (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                          >
                            {option.patientIdentifier} 
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                }

                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    autoFocus
                    InputLabelProps = {{readOnly: true, shrink:true}}
                    label="Device Location"
                    value = {currentDeviceLocation}>
                    
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <FormControl>
                    <InputLabel shrink={true}
                        >Select Location</InputLabel>
                    <Select 
                        id="location"
                        label="Location"
                        value = {currentDeviceLocation}
                        // m={2}
                        onChange={(event) => {
                          setDialogValue({ ...dialogValue, org: event.target.value});
                          setCurrentDeviceLocation(event.target.value)
                          }
                        }
                      >
                        {allLocations && allLocations.map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                          >
                            {option} 
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                

                <Grid item xs={4} sm={4} md={4}>
                  <TextField
                    variant="outlined"
                    inputProps = {{readOnly: true}}
                    label="Current Status"
                    value = {deviceStatus}>
                    
                    </TextField>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Button variant='outlined' color='primary' type='submit'>
                    {isNewDevice? 'Create': 'Update'}
                    </Button>
                </Grid>
            </Grid>
            </form>
          </CardContent>
          </Card>
          </Grid>

        <Grid item  xs={12} sm={12} md={12}>
        <Accordion expanded={expandMgmtAccordion}>
            <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon color="primary"/>}
                IconButtonProps={{
                  onClick:handleMgmtAccordion
                }}
                id="subject-history-header"
            >
                <Typography
                    color="secondary"
                    variant="h6"
                >
                    Manage Device
                </Typography>
               
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                

                <Grid item md={4} sm={4} xs={4}>
                   
                  <Button variant='outlined' onClick={handleDeviceStart} color='primary'>
                    Start
                    </Button>
                </Grid>
                <Grid item md={8} sm={8} xs={8}>
                    <Typography variant="caption">
                      Place the Kiwi lead on a stationary surface. When calibration is complete, place it on the
                      bubble chamber or the subject.
                    </Typography>
                </Grid>
                
                <Grid item md={12} sm={12} xs={12}>
                    <Typography variant="h7" weight="bold" color='secondary'>
                        {deviceStatusMessage}
                    </Typography>
                </Grid>
                

                <Grid item md={4} sm={4} xs={4}>
                   
                  <Button variant='outlined' onClick={handleDeviceStop} color='primary'>
                    Stop
                    </Button>
                </Grid>
                <Grid item md={8} sm={8} xs={8}>
                    <Typography variant="caption">
                        Stop the device.
                    </Typography>
                </Grid>
                

                
                </Grid>
            </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item  xs={12} sm={12} md={12}>
              <Accordion expanded={expandEventsAccordion} >
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        expandIcon={<ExpandMoreIcon color="primary"/>}
                        id="subject-history-header"
                        IconButtonProps={{
                          onClick:handleEventsAccordion
                        }}
                    >
                        <Typography
                            color="secondary"
                            variant="h6"
                        >
                            Events
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container>
                    {device &&
                      <Grid item xs={12} sm={12} md={12}>
                      <Card>
                      <CardContent>


                        <CpapEventInformationTable 
                                  editOrCreateCpapEvent={editOrCreateCpapEvent}
                                  initialAuthLevelFilter={initialAuthFilter}
                                  selectedDevice={deviceId}
                        />
                          

                          

                      </CardContent>
                      </Card>
                      </Grid>
                    }
                    {device &&
                      <Grid item xs={12} sm={12} md={12}>
                      <Card>
                      <CardContent>


                        <WiggleEventInformationTable 
                                  editOrCreateWiggleEvent={editOrCreateWiggleEvent}
                                  initialAuthLevelFilter={initialAuthFilter}
                                  selectedDevice={deviceId}
                        />
                          

                          

                      </CardContent>
                      </Card>
                      </Grid>
                    }
                    </Grid>

                    </AccordionDetails>
                </Accordion>
        </Grid>
                      
        { deviceHistory && <Grid item  xs={12} sm={12} md={12}>
        <div>
        <Accordion expanded={expandAccordion}>
            <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon color="primary"/>}
                id="subject-history-header"
                IconButtonProps={{
                  onClick:handleAccordion
                }}
            >
                <Typography
                    color="secondary"
                    variant="h6"
                >
                    Device History
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                <DeviceHistoryTable
                    allHistory={deviceHistory}
                    displayDeviceSnapshot = {displayDeviceSnapshot}
                    />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <Typography variant="caption">
                        The history of updates to this device.
                    </Typography>
                </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>

        <DeviceHistorySnapshotDialog 
          isOpen={deviceSnapshotDialogIsOpen} 
          dialogComplete={snapshotDialogComplete} 
          deviceInformation={deviceSnapshotToDisplay} 
          allOrgs={allOrgs}/>

        </div>
        </Grid>
        }




        </Grid>
          
        </DialogContent>
        </Dialog>



        <CpapEventInfoDialog 
          dialogComplete = {cpapEventDialogComplete}
          isOpen={cpapEventCreateDialogIsOpen}
          eventInformation={cpapEventToUpdate}
          deviceId={deviceId}
          currentSubjectForDevice={currentSubject}
        />




        <WiggleEventInfoDialog 
          dialogComplete = {wiggleEventDialogComplete}
          isOpen={wiggleEventCreateDialogIsOpen}
          eventInformation={wiggleEventToUpdate}
          deviceId={deviceId}
          currentSubjectForDevice={currentSubject}
        />
        

        </WiggleEventListProvider>
      </CpapEventListProvider>
    );
}