import React,  { useEffect, useState }  from 'react';
import {
  // Grid,
  // Paper,
  Typography,
  Divider,
  // Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


import {UserInfoDialog} from '../../dialogs/UserInfoDialog';

import { SubjectTable } from './components/subjecttable';
import { SubjectInformationTable } from './components/SubjectInformationTable';
import { SubjectInfoDialog } from '../../dialogs/SubjectInfoDialog';
import { 
  // emptyParentUIState,
  // UIPropsForNewParentDialog,
  UserProps,
  emptySubjectState,
  SubjectProps
} from '../../common/entityUtilities';

import {useUserState} from '../../context/UserContext';
import { useSubjectListState } from '../../context/SubjectListContext';

import { getAllSubjects } from '../../common/apiGetUtilities';
import { getAllOrgs } from '../../common/apiGetUtilities';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexDirection:'column',
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Subjects(props:any):JSX.Element{
  const initialAuthFilter = props?.history?.location?.state?.initialAuthFilter;
  const classes = useStyles();
  const activeUser = useUserState();
  const [parentCreateDialogIsOpen, setParentCreateDialogIsOpen] = useState(false);
  // null is used to reset the ui, kind of hackish...
  const [subjectToUpdate, setSubjectToUpdate] = useState<SubjectProps|null>(emptySubjectState);

  const [allOrgs, setAllOrgs] = useState<any[]>([]);


  useEffect( ()=>{
    const getOrgs = async () => {
        // get all the orgs
        console.log('Active user:', activeUser);
        const orgs = await getAllOrgs(activeUser);
        setAllOrgs(orgs);
    };
    
    getOrgs();

    return () => {
        // this now gets called when the component unmounts
    };
  }, [activeUser]);



  const editOrCreateUser = (userInfo:SubjectProps|null) =>{
    //Order is important, first make visible then update user

    setParentCreateDialogIsOpen(true);
    console.log('The subjectinfo', userInfo);

    setSubjectToUpdate(userInfo);
  };

  const dialogComplete= () =>{
    // Reset the user to create/edit
    // doesn't seem to work if you reset in the dialog.
    setSubjectToUpdate(null);//reset the ui
    // setParentCreateDialogIsOpen(false);
    setParentCreateDialogIsOpen((prev)=> !prev);
  };

  // This should really be dealt with on the router level...
  if (activeUser.authLevel < 10){
    return (
      <Typography variant="h5">
        You don&apos;t have permission to view this page.
      </Typography>
    );
  }


  return (
    <div className={classes.root}>
      <Typography
        display="inline"
        gutterBottom
        variant="h3"
      >
            Manage Subjects
      </Typography>
      <Divider />

      <SubjectInformationTable
        editOrCreateUser = {editOrCreateUser}
        initialAuthLevelFilter = {initialAuthFilter}
      />
      <SubjectInfoDialog 
        dialogComplete = {dialogComplete}
        isOpen={parentCreateDialogIsOpen}
        subjectInformation={subjectToUpdate}
        allOrgs = {allOrgs}
      />
    </div>
  );
};
