import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {blueGrey} from '@mui/material/colors/';
import { firebaseAuth } from '../../../firebaseConfig';
import firebase from 'firebase/app';
import {RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth';
import {isMobile, isBrowser, isIOS, isAndroid} from 'react-device-detect';
import { cfaSignInPhone, cfaSignInPhoneOnCodeSent, cfaSignInPhoneOnCodeReceived } from 'capacitor-firebase-auth';

import Loading from '../../dashboard/components/Loading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  Grid,
  InputLabel,
  TextField,
  Paper,
  MenuItem,
  Typography,
  CardContent,
  Card,
  Snackbar, 
  Button
} from '@material-ui/core';

// styles
import useStyles from './styles';
// import classnames from 'classnames';

// logo
import google from '../../../images/google.svg';
// context
import { useUserDispatch, loginUser, resetPassword, registerUser, externalUserVerification } from '../../../context/UserContext';

import * as AppRoutes from '../../../common/routeNames';
import ResetPassword from '../resetpassword/ResetPassword';

function Login(props) {
  const classes = useStyles();

  // global
  const userDispatch = useUserDispatch();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [activeTabId, setActiveTabId] = useState(0);
  // const [nameValue, setNameValue] = useState('');
  const [authMessage, setAuthMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpValue, setOTP] = useState('');
  const [otpMessage, setOtpMessage] = useState('Enter the code sent to your phone');
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [phoneVerificationId, setPhoneVerificationId] = useState('');
  const [country, setCountry] = useState('USA');
  const [countryCode, setCountryCode] = useState('+1');
  const [isRegister, setRegister] = useState(false);

  useEffect(() =>{
    if(authMessage === ''){
      setIsSnackbarOpen(false);
    }else{
      setIsSnackbarOpen(true);
    }
  },[authMessage]);
  
  const countries = [
    { 
      value:'+1',
      label:'USA'
    }
  ];

  const handleCountryChange = (e) => {
    console.log('Selected country:', e.target.value);
    let country = countries.find( (element)=>element.value==e.target.value);
    setCountry(country.label);
    console.log('Country:', country.label);
    console.log('Country code:', country.value);
    setCountryCode(country.value);
  };


  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      setIsSnackbarOpen(false);
      return;
    }
    // setIsSnackbarOpen(false);
  };
  
  const generateRecaptcha = () => {

    window.RecaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        //recaptcha verified...sign in with phone number now
      }, firebaseAuth
    });

  };

  const requestOTP = (e) => {
    e.preventDefault();
    console.log('requesting OTP');
    // set the phoneNumber with country code
    const numberWithCountryCode = countryCode + phoneNumber;
    console.log('Phone number with country code:', numberWithCountryCode);
    if (isMobile) {
      console.log('this is a mobile device');
      if (isIOS){
        console.log('It is a iOS device');
      }
      if (isAndroid){
        console.log('It is an Android device!');
      }
    }
    if (phoneNumber.length >= 10){
      console.log('Expand form');
      setExpandForm(true);
      if(!isIOS){//Not IOS
        generateRecaptcha();
        let verifier = window.RecaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(numberWithCountryCode, verifier)
          .then(confirmationResult => {
            window.confirmationResult = confirmationResult;
          }).catch((error) => {
            console.log('error:',error);
          });
      }
      else{ //isIOS 
        console.log('Signing in phone:', numberWithCountryCode);
        cfaSignInPhone(numberWithCountryCode).subscribe(
          {
            error: (error) => {
              console.log('at error cfaSignInPhone!');
              console.log(error);
    
            },
            complete: () => {
              console.log('at complete cfaSignInPhone!');
            }
          }
        );

        cfaSignInPhoneOnCodeSent().subscribe(
          {
            next: (verificationId) => {
              console.log('at next cfaSignInPhoneOnCodeSent!');
              setPhoneVerificationId(verificationId);
              console.log('phone verification id:',verificationId);
            },
            error: (error) => {
              console.log('at error cfaSignInPhoneOnCodeSent!');
              console.log(error);

            },
            complete: () => {
              console.log('at complete cfaSignInPhoneOnCodeSent!');
            }
          }
        );

        cfaSignInPhoneOnCodeReceived().subscribe(
          {
            next: (verificationId, verificationCode) => {
              console.log('Ver id, code:', verificationId, verificationCode);
            },
            error: (error) => {
              console.log('Error at cfaSignInPhoneOnCodeReceived:', error);
            },
            complete: ()=> {
              console.log('at complete cfaSignInPhoneOnCodeReceived');
            }
          }
        );


      }
    }
  };


  const verifyOTP = (e) => {
    e.preventDefault();
    setOTP(e.target.value);
    let verificationCode = e.target.value;
    if (!isIOS){
      let localOTP = e.target.value;
      console.log('OTP:', e.target.value);
      if (localOTP.length === 6){
        console.log('OTP:', localOTP);
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(localOTP).then( (result) => {
          //user signed successfully
          const user = result.user;
          console.log('User signed in:', user);
        }).catch( (error) => {
          console.log('Unsuccessful login, ', error);
        });
      }
    }
    else{ //iOS 
      
      console.log('iOS verification code:',verificationCode);
      if (verificationCode != null && verificationCode.length == 6) {
        const credential = firebase.auth.PhoneAuthProvider.credential(phoneVerificationId, verificationCode);
        console.log('Credential:',credential);
        console.log('putting cred into signIn');
        setOtpMessage('Trying to sign in');
        firebase.auth().signInWithCredential(credential).then((auth) => {
          console.log('done with phone signin!');
          console.log(auth);
        }).catch((error) => {
          console.log('ERROR in SignInWithCredential');
          console.log(error);
          setOtpMessage('Verify code and re-enter please. You can also request code again.');
        }).finally( ()=> {
          setOtpMessage('Verify code and re-enter please. You can also request code again.');
        });
      }
    }
  };

  return (
    <Paper className={classes.wrapper} >
      <Loading isLoading={isLoading}/>
      <Grid
        container
        direction={'column'}
        justify="left"
        align="center"
        spacing={3}
      >
        <Grid
          item
          xs={12} sm={8}
        >  
          <Paper
            background={blueGrey[100]}
            elevation={3}
            padding={4}
          >
            <Card variant="outlined">
              <CardContent>
                <Typography
                  align="center"
                  component="h1"
                  gutterBottom
                  variant="h3"
                  color='secondary'
                >
                  Welcome to Project Kiwi!
                </Typography>
                <Box
                  align="center"
                >
                  { 
                  <Button
                    className={classes.googleButton}
                    disabled={
                      isLoading
                    }
                    onClick={()=>externalUserVerification(
                      setIsLoading, 
                      setError, 
                      setAuthMessage)}
                      
                  >
                    <img
                      alt="google"
                      className={classes.googleIcon}
                      src={google}
                    />
                    &nbsp;Sign in with Google
                  </Button>
                  }
                </Box>  
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
        >
          <Paper
            elevation={3}
            padding={4}
          >
            <Card>
              <CardContent> 
                <Typography
                  align="center"
                  component="h2"
                  variant="h5"
                  color='secondary'
                >
                Alternate sign in
                </Typography>

              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  expandIcon={<ExpandMoreIcon />}
                  id="update-assessment-header"
                >
                  <Typography>Use your email</Typography>
                </AccordionSummary>
                <AccordionDetails>
                 
                  <Grid container xs={12} sm={12} spacing={1}>
                    <form>
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                      >
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input
                          autoComplete="email"
                          autoFocus 
                          id="email"
                          name="email"
                          onChange={e => setLoginValue(e.target.value)}
                          value={loginValue}
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                      >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                          autoComplete="current-password"
                          id="password"
                          name="password"
                          onChange={e => setPasswordValue(e.target.value)}
                          type="password"
                          value={passwordValue}
                        />
                      </FormControl>
                      {isRegister && 
                      
                      <FormControl
                        fullWidth
                        margin="normal"
                        required
                      >
                        <InputLabel htmlFor="password">Confirm Password</InputLabel>
                        <Input
                          id="confirmpassword"
                          name="confirmpassword"
                          onChange={e => setConfirmPasswordValue(e.target.value)}
                          type="password"
                          value={confirmPasswordValue}
                        />
                      </FormControl>}
                      {/* <FormControlLabel
                        control={<Checkbox
                          color="primary"
                          value="remember"
                        />}
                        label="Remember me"
                      /> */}
                    </form>

                    <Grid item xs={5} sm={5}>
                        <Button
                          color="primary"
                          disabled={
                            loginValue.length === 0 || passwordValue.length === 0 || isLoading
                          }
                          fullWidth
                          mb={2}
                          onClick={()=>
                            {
                              !isRegister ? 
                              loginUser(userDispatch,loginValue,passwordValue,props.history,setIsLoading,setError,setAuthMessage):
                              registerUser(userDispatch,loginValue,passwordValue,confirmPasswordValue,
                                props.history,setIsLoading,setError,setAuthMessage, setRegister);
                            }
                          }
                          variant="outlined"
                        >
                          {isRegister ? "SIGNUP" : "SIGN IN"}
                        </Button>
                      </Grid>
                      <Grid item xs={7} sm={7}>  
                        <Button
                          color="secondary"
                          disabled={
                            isLoading
                          }
                          onClick={ ()=> resetPassword(loginValue, setIsLoading, setAuthMessage)}
                          //component={Link}
                          //fullWidth
                          //to={AppRoutes.REST_PASSWORD}
                        >
                        Forgot password?
                        </Button>
                      </Grid>

                      <Card>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={8} sm={8}>
                                <Typography> {!isRegister ? 
                                  "Don't have an account? Create one" :
                                  "Already have an account? Login here"} </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4}>
                                <Button color="primary"
                                  onClick={() => setRegister(!isRegister)}>
                                  {!isRegister ? "SIGNUP" : "LOGIN"}
                                </Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                      </Card>
                  </Grid>

                </AccordionDetails>
              </Accordion> 
              
              </CardContent>
            </Card>
          </Paper>
        </Grid> 
      </Grid>
      <div id="recaptcha-container">

      </div>
      <Snackbar
        autoHideDuration={3000}
        message={authMessage}
        onClose={closeSnackBar}
        open={isSnackbarOpen}
      />  
    </Paper>
  );
}

export default withRouter(Login);