import React, {useEffect, useState} from 'react';
import { Button , FormControlLabel, MuiThemeProvider, TextField, createMuiTheme} from '@material-ui/core';
import MUIDataTable, {FilterType, SelectableRows} from 'mui-datatables';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useSubjectListState } from '../../../context/SubjectListContext';
import { SubjectProps } from '../../../common/entityUtilities';
import { useSubjectState } from '../../../context/UserContext';
import { useUserState } from '../../../context/UserContext';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@material-ui/core/styles';
import { muiDataTableTheme } from '../../../themes/default';
export function SubjectHistoryTable(props) {
   
  const initialColumns = [
    {
      name:'patientIdentifier',
      label:'Patient ID',
      options: { }
    },
    {
      name:'dob',
      label:'DOB',
      options: { 
        customBodyRender: (value, tableMeta, updateValue) => (
          // <FormControlLabel
          //   label=""
          //   value={value}
          //   control={<TextField value={new Date(value).toLocaleDateString()} />}
          // />
          new Date(value).toLocaleDateString()
        )}
    },  
    {
      name:'updatedAt',
      label:'Update Time',
      options:{
        customBodyRender: (value, tableMeta, updateValue) => (
          // <FormControlLabel
          //   label=""
          //   value={value}
          //   control={<TextField value={new Date(value).toLocaleDateString()} />}
          // />
          new Date(value).toLocaleDateString() + ' ' + new Date(value).toLocaleTimeString('en-US')
        )}
    },
  ];
  const {displaySubjectSnapshot, initialAuthLevelFilter, allHistory} = props;
  const activeUser = useUserState();
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(initialColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);

  const handleClick = (event)=>{
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ()=>{
    setAnchorEl(null);
  }

  const rowClick = (rowData, rowMeta) => {
    console.log(rowData, 'Row click', rowMeta);
    console.log('Data: ',tableData[rowMeta.dataIndex]); //rowIndex is for visible index
    setRowData(tableData[rowMeta.dataIndex]);
    displaySubjectSnapshot(tableData[rowMeta.dataIndex]);
    
  };

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    download:false,
    filter:true,
    print:false,
    search:false,
    selectableRows:'none',
    onRowClick:rowClick,
    // eslint-disable-next-line react/no-multi-comp
    // customToolbar: () => {
    //   return (
    //     <Button 
    //       color="primary"
    //       onClick={()=> {editOrCreateUser(null);}}
    //       variant="outlined"
    //     >
    //       Add Subject
    //     </Button>
    //     // <CustomToolbar />
    //   );
    // }
  };

  useEffect(() =>{
    console.log('Setting up all history for subject:', allHistory);
    let toDisplay = allHistory;
    const currentAuthLevel = activeUser.authLevel;
    if(currentAuthLevel <=0){
      // hopefully this is never undefined
      toDisplay = null;
    }
    console.log('Setting table to display:', toDisplay);
    setTableData(toDisplay);
  }, [allHistory]);

  useEffect(() => {
    if(initialAuthLevelFilter !== undefined){
      setColumns(
        [
          {
            name:'patientIdentifier',
            label:'Patient ID',
            options: { }
          },
          {
            name:'dob',
            label:'Date of Birth',
            options: {
              customBodyRender: (value, tableMeta, updateValue) => (
                <FormControlLabel
                  label=""
                  value={new Date(value).toLocaleDateString}
                  control={<TextField value={new Date(value).toLocaleDateString} />}
                  onChange={event => updateValue(event.target.value)}
                />
              )
             }
          },    
          {
            name:'updatedAt',
            label:'Date of update',
            options:{}
          },  
        ]);
    }
  }, [initialAuthLevelFilter]);

  return(
    <ThemeProvider theme={muiDataTableTheme}>
        <MUIDataTable
        columns={columns}
        data={tableData}
        options={options}
        title={'Subject History'}
        />

        <Popover
          id="simple-popper"
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography>The content of the Popover.</Typography>
        </Popover>
    </ThemeProvider>
  );
}