import React, {useEffect} from 'react';
// import {userData} from '../common/staticdata';
import { getAllSubjects } from '../common/apiGetUtilities';
import { useUserState } from './UserContext';
import { UserProps, SubjectProps, convertUserDataToUserPropType, emptyUserState } from '../common/entityUtilities';
type Props = {
  children:React.ReactNode;
};
enum SubjectListTypes {
  LOAD = 'LOAD',
  RESET = 'RESET',
  ADD_SUBJECT = 'ADD_SUBJECT',
  UPDATE_SUBJECT = 'UPDATE_SUBJECT'
}
type SubjectListProps = {
  allSubjects:SubjectProps[]  
}
interface SubjectListAction extends SubjectListProps{
  type:SubjectListTypes;
}

const intialSubjectList:SubjectListProps = {
  allSubjects:[]
};



function subjectListReducer(state:SubjectListProps, action:SubjectListAction){
  switch(action.type) {
    case SubjectListTypes.LOAD:{
      const {type, ...noType} = action;
      console.log('Subject Dispatch:', noType);
      return noType;
    }
    case SubjectListTypes.RESET:
      return { ...state, ...intialSubjectList};
    case SubjectListTypes.ADD_SUBJECT:{
      const list = state.allSubjects;
      const newSubject = action.allSubjects;
      const oldUserSet = new Set(list.map(item=>item.patientIdentifier));
      for(const subject of newSubject){
        if(!oldUserSet.has(subject.patientIdentifier)){
          console.log('[subjectlistcontext] Adding subject', subject.patientIdentifier);
          list.push(subject);
        }
      }      
      const listOfSubjectProps:SubjectListProps = {
        allSubjects:list
      };
      return {  ...listOfSubjectProps};
    }
    case SubjectListTypes.UPDATE_SUBJECT:{
      const origList = state.allSubjects;
      console.log('origlist of events:', origList);
      console.log('update:', action.allSubjects);
      const updateUserList = action.allSubjects;
    
      const userValueDict = new Map();
      
      for (const user of updateUserList) {
        const {patientIdentifier, ...others} = user;
        userValueDict.set(patientIdentifier, others);
      }
    
      const newUserSet = new Set(updateUserList.map(item=>item.patientIdentifier));
      const newList = origList.map((item) => {
        const userId = item.patientIdentifier;
        if (newUserSet.has(userId)) {
          const updateValues = userValueDict.get(userId);
          const updatedItem = {
            ...item,
            ...updateValues
          };   
          return updatedItem;
        }   
        return item;
      });  
      const listOfSubjectProps:SubjectListProps = {
        allSubjects:newList
      };
      return {  ...listOfSubjectProps};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const SubjectListStateContext = React.createContext<SubjectListProps>(null!);
const SubjectListDispatchContext = React.createContext<React.Dispatch<any>>(null!);

function SubjectListProvider({ children }:Props) {
  const user = useUserState();
  //so ugly. Pretty sure the types are incorrect/all over the place
  const [state, dispatch] = React.useReducer(subjectListReducer, 
    intialSubjectList);
    
  useEffect(()=>{
    async function loadSubject(){
      console.log('[subjectlistcontext] ', user);
      if(user.authLevel <0 ) return;
      const subjects = await getAllSubjects(user);
      const initSubjects:SubjectProps[] = [];
      for(const rawUser of subjects){
        initSubjects.push(rawUser);
      }
      dispatch({ type: SubjectListTypes.LOAD, allSubjects:initSubjects });
    }
    loadSubject();
  }, [user]);
  return (
    <SubjectListStateContext.Provider value={state}>
      <SubjectListDispatchContext.Provider value={dispatch}>
        {children}
      </SubjectListDispatchContext.Provider>
    </SubjectListStateContext.Provider>
  );
}

function useSubjectListState() {
  const context = React.useContext(SubjectListStateContext);
  if (context === undefined) {
    throw new Error('SubjectListStateContext must be used within a SubjectListProvider');
  }
  return context;
}

function useSubjectListDispatch() {
  const context = React.useContext(SubjectListDispatchContext);
  if (context === undefined) {
    throw new Error('useSubjectDispatch must be used within a SubjectListProvider');
  }
  return context;
}

export { SubjectListProvider, useSubjectListState, useSubjectListDispatch,SubjectListTypes };
