import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
};

// Exporting only the part of firebase that are needed would be
// ideal
firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth();
const firebaseStorage = firebase.storage();

export {firebaseAuth, firebaseStorage, firebase as default};