import {useState, useEffect} from 'react';

// import PropTypes from 'prop-types';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // Divider,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {  KeyboardDatePicker } from '@material-ui/pickers';
import {
  DeviceLocation,
  SubjectProps,
} from '../common/entityUtilities';


import { VentilationMode, Apparatus } from '../common/entityUtilities';




type UIPropsForNewSubjectDialog = {
  birthWeight: number,
  currentWeight: number,
  patientIdentifier: string,
  dob:Date|undefined,
  length?:number|undefined,
  headCirc:number|undefined,
  chestCirc:number|undefined,
  abdominalCirc:number|undefined,
  ventilationMode:string|undefined,
  dueDate:Date|undefined,//needs to be nullalbe in ui but null== Date(0) for db
  org: string|undefined,
  devices: string[]|undefined,
  deviceLocation: string,
  apparatus: string,
  createdAt?: Date| undefined
};

const emptySubjectUIState:UIPropsForNewSubjectDialog = {
  birthWeight: 0,
  patientIdentifier: '',
  dob: new Date(),
  dueDate: new Date(),
  length: 0,
  headCirc: 0,
  chestCirc: 0,
  abdominalCirc: 0,
  ventilationMode: VentilationMode.Bubble,
  deviceLocation: DeviceLocation.CHAMBER,
  apparatus: '',
  org: '',
  devices: [],
  currentWeight: 0,
  createdAt: new Date()
};

export type SubjectHistorySnapshotDialogProps = {
  isOpen:boolean,
  dialogComplete:()=>void,
  subjectInformation:SubjectProps|null,
  allOrgs: any[]

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(2),
        width: 200,
      },
    },
  }),
);

export const SubjectHistorySnapshotDialog = (props:SubjectHistorySnapshotDialogProps):JSX.Element=> {
  const classes = useStyles();
  const {isOpen, dialogComplete, subjectInformation, allOrgs}  = props;
  

  const [dialogValue, setDialogValue] = useState(emptySubjectUIState);
  const [org, setOrg] = useState('');
  const [ventilationMode, setVentilationMode] = useState("");

  const [errors, setErrors] = useState<any>({});

  const allModes = [VentilationMode.HFJV, VentilationMode.HFOV, VentilationMode.Bubble];
  const allApparatus = [Apparatus.Canula, Apparatus.Mask, Apparatus.Prongs];

  useEffect(()=>{

    const fillUp = async ()=>{
      // The userinfo is of type UserProps
      if(subjectInformation===null){
        console.log('New subject initializing');
        setDialogValue(emptySubjectUIState);
        return;      
      }


      console.log('userInformation in [useEffect]:', subjectInformation);
      let dialogValues:UIPropsForNewSubjectDialog =  {...emptySubjectUIState, 
        patientIdentifier: subjectInformation.patientIdentifier,
        abdominalCirc: subjectInformation.abdominalCirc,
        birthWeight: subjectInformation.birthWeight,
        currentWeight: subjectInformation.currentWeight,
        chestCirc: subjectInformation.chestCirc,
        dob: subjectInformation.dob, dueDate: subjectInformation.dueDate,
        length: subjectInformation.length,
        ventilationMode: subjectInformation.ventilationMode,
        devices: subjectInformation.devices,
        org: subjectInformation.org,
        apparatus: subjectInformation.apparatus,
        createdAt: subjectInformation?.createdAt
      };
      console.log('Setting dialogValues:', dialogValues);
      setDialogValue(dialogValues);
      if (subjectInformation?.ventilationMode){
        setVentilationMode(subjectInformation.ventilationMode);
      }
      if (subjectInformation?.org){
        setOrg(subjectInformation?.org);
      }

      console.log('Ventilation Mode:', subjectInformation.ventilationMode );
      console.log('Apparatus:', subjectInformation.apparatus);
      console.log('Org:', subjectInformation?.org);
      const orgName = allOrgs.find(item=>item._id==dialogValues.org)?.name;
      console.log('Org name:', orgName);

 

    }

    console.log('subjecttoupdate:', subjectInformation);
    fillUp();
    
   
  },[subjectInformation]);
  


  


  const handleClose = () => {
    // new way of reseting dialog state when closing.
    setErrors({});
    setDialogValue(emptySubjectUIState);
    dialogComplete();
  };


  


  //Ugh, this UI should to be split/updated for two components, one for user and the
  // other for parents
  return (

    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={isOpen}
    >
      <form>

        <DialogContent className={classes.root}>
          <div>
            <Typography
              variant="h5"
            >
                Subject History
            </Typography>

            <TextField
              autoFocus
              disabled={true}
              label="Time stamp"
              value={dialogValue?.createdAt && 
                new Date(dialogValue?.createdAt).toLocaleDateString('en-us') + ", " + 
                new Date(dialogValue?.createdAt).toLocaleTimeString('en-us')}/>

            <TextField
              autoFocus
              disabled={true}
              error = {errors.email}
              fullWidth
              helperText = {errors.email}
              id="patientidentifier"
              // inputProps={{
              //   readOnly: !isNewSubject
              // }}
              label="Patient Identifier"
              margin="dense"
              style={{ width: '80%' }}
              type="text"
              value={dialogValue.patientIdentifier}
            />

            <TextField
            autoFocus         
            disabled={true}       
            id="birthweight"
            label="Birth Weight (g)"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, birthWeight: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.birthWeight}
            />

            <TextField
            autoFocus           
            disabled={true}             
            id="birthweight"
            label="Current Weight (g)"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, currentWeight: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.currentWeight}
            />


            <TextField
            autoFocus            
            disabled={true}            
            id="length"
            label="Length (cm)"
            margin="dense"
            onChange={(event) => setDialogValue({ ...dialogValue, length: parseFloat(event.target.value) })}
            type="number"
            value={dialogValue.length}
            />




            <TextField
              autoFocus
              disabled={true}
              label="Birth Date"
              value={dialogValue?.dob && 
                new Date(dialogValue.dob).toLocaleDateString('en-us')} />


            <TextField
              autoFocus
              disabled={true}
              label="Due Date"
              value={dialogValue?.dueDate && 
                new Date(dialogValue.dueDate).toLocaleDateString('en-us')} />


            



          
            <FormControl fullWidth>
            <InputLabel shrink={true} 
                 >Org</InputLabel>
            <Select 
                id="org"
                label="Organization"
                disabled={true}
                value = {allOrgs.find(item=>item._id==org)?._id}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, org: event.target.value});
                  setOrg(event.target.value);
                  console.log('Current org:', org);
                  console.log('Setting Org:', event.target.value);
                  }
                }
              >
                 {allOrgs && allOrgs.map((option) => (
                  <MenuItem
                    key={option._id}
                    value={option._id}
                  >
                    {option.name} @ {option.location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
            <InputLabel shrink={true} 
                 >Apparatus</InputLabel>
            <Select 
                id="apparatus"
                disabled={true}
                label="Apparatus"
                value = {allApparatus.find(item=>item==dialogValue.apparatus)}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, apparatus: event.target.value});
                  }
                }
              >
                 {allApparatus && allApparatus.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option} 
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
            <FormControl fullWidth>
            <InputLabel >Ventilation Mode</InputLabel>
            <Select 
                id="ventilationMode"
                label="Ventilation Mode"
                disabled={true}
                value={dialogValue.ventilationMode}
                // m={2}
                onChange={(event:any) => {
                  setDialogValue({ ...dialogValue, ventilationMode: event.target.value});
                  setVentilationMode(event.target.value);
                  console.log('Current mode:', ventilationMode);
                  console.log('Setting ventilationMode:', event.target.value);
                  }
                }
              >
                {allModes && allModes.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>         

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant='outlined'
            onClick={handleClose}
          >
              Close
          </Button>
        
        </DialogActions>
      </form>

    </Dialog>
  );
};
